import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import nextynBg from '../assets/img/nextyn_bg.png';
import { Link } from 'react-router-dom'
import HelmetComponent from '../utils/HelmetComponent';
function Compliance() {
    return (
        <div>
            <HelmetComponent title={'Nextyn | Compliance'} desc={'Nextyn has employed mandatory compliance procedures and rules for every micro-consulting assignment undertaken on its platform'} />
            <Header />
            <section className='mt-100'>
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className='text-center'>
                                <h1 className="colord-heading text-large ">Nextyn Compliance Framework</h1>
                            </div>
                            <div className='mt-100 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Expert Compliance</h4>
                                <p>Nextyn has employed mandatory compliance procedures and rules for every micro-consulting assignment undertaken on its platform. Every Expert registered with us, or willing to register on the platform must agree, accept and read, in detail our Terms & Conditions before undertaking a consultation with us. These Compliance procedures are designed to outline, educate and provide our Experts with clear boundaries and address sensitive subjects while undertaking a micro-consulting call.</p>

                                <p>Our Experts are informed and instructed about our compliance standards through a mandatory tutorial that is designed to explain our confidentiality Terms & Conditions and outline scenarios that the Expert could potentially encounter while undertaking a consultation with Nextyn. Our mandatory induction tutorial also provides a series of questions that require our Experts to gauge the ideal outcome of a situation they could encounter during a consultation.</p>

                                <p>In order to qualify as an Expert on our platform, every Expert is mandated to go through the Induction Tutorial and the Confidentiality Terms & Conditions confirm that have read, understood, agree and will adhere to the samewhile undertaking a consultation with Nextyn.</p>
                            </div>
                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Employer Registry</h4>

                                <p>Nextyn practices strict measures to refrain from accepting or conducting consultations with Experts who’s employers bars its employees from participating in such third-party consultations. In an instance when Nextyn learns of any such prohibition, it records the details of the company in its Backend Registry. This registry list is automated into Nextyn’ IT platform to ensure Experts identified in our database as employees of companies on the list are not permitted to participate in third party consultations.</p>
                            </div>
                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Client Compliance</h4>
                                <p>All our clients are mandated to sign a service contract, which outlines and highlights our compliance standards and our confidentiality terms. Upon signing the Service Contract, our clients agree, understand and accept these Confidentiality clauses to ensure every Micro-Consulting call undertaken by them, discusses only publicly available non-proprietary information.</p>
                            </div>
                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Data Compliance</h4>
                                <p>Our Expert and Client data is compliant with the European Union General Data Protection Regulation, which is outlined in detail in our Privacy Policy.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section className="contactUs mt-100">
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className="pos-relative">
                                <img src={nextynBg} className="width100" alt="bg" />
                                <div className="nextynBg">
                                    <h1 className="mb-3">Big enough to deliver,</h1>
                                    <h1 className="mb-5">small enough to care</h1>
                                    <Link to={'/contact-us'}><button className="btn getInTouch">Get in touch</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    )
}

export default Compliance