import React, { useEffect, useState } from "react";
import singleNewsInsightImg from "../assets/img/singleNewsInsight.png";
import { Link, useParams } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import Header from "../Components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import newInsights from "../assets/img/newInsights.png";
import leftArrow from "../assets/img/leftArrow.svg";
import rightArrow from "../assets/img/rightArrow.svg";
import "swiper/css";
import Footer from "../Components/Footer";
import nextynBg from "../assets/img/nextyn_bg.png";
import blogs from "../API/Insights";
import parse from "html-react-parser";
import Insights from "../Components/Insights";
import HelmetComponent from "../utils/HelmetComponent";
import Helmet from "react-helmet";
function SingleNewsAndInsight() {
  const [swiper, setSwiper] = useState();
  const [slideBeginning, setSlideBeginning] = useState(true);
  const [slideEnd, setSlideEnd] = useState(false);
  function changeSlide() {
    setSlideBeginning(swiper?.isBeginning);
    setSlideEnd(swiper?.isEnd);
  }
  const params = useParams();
  const [blogId, setBlogId] = useState();
  let timeout;
  let scroll1 = 0;
  useEffect(() => {
    window.onscroll = () => {
      scroll1 = window.scrollY;
      timeout = setTimeout(() => {
        if (scroll1 >= 150) {
          document.getElementById("header").classList.add("sticky");
        } else {
          document.getElementById("header").classList.remove("sticky");
        }
      }, 10);
    };
  }, []);

  function getQueryVariable(variable) {
    // var query = window.location.search.substring(1);
    // console.log(query)//"app=article&act=news_content&aid=160990"
    // var vars = query.split("&");
    // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    // for (var i = 0; i < vars.length; i++) {
    //     var pair = vars[i].split("=");
    //     console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    //     if (pair[0] == variable) { return pair[1]; }
    // }
    // return (false);
    let index = blogs.findIndex((o) => o.url === params[variable]);
    if (index >= 0) {
      return index;
    } else return 0;
  }
  function truncate(str, length) {
    return str.length > length ? str.substring(0, length) + "..." : str;
  }

  let url =
    `https://nextyn.com/articles/` + blogs[getQueryVariable("blogId")].url;
  let title = blogs[getQueryVariable("blogId")].title;
  let meta_desc = blogs[getQueryVariable("blogId")].meta_desc;
  let img = blogs[getQueryVariable("blogId")].img;
  let timeStamp = blogs[getQueryVariable("blogId")].timeStamp;
  const articleStructured = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": { url },
    },
    headline: { title },
    description: { meta_desc },
    image: { img },
    author: {
      "@type": "Person",
      name: "Mehul Singh",
      url: "https://www.linkedin.com/in/mehul-singh-26103a143/",
    },
    publisher: {
      "@type": "Organization",
      name: "Nextyn",
      logo: {
        "@type": "ImageObject",
        url: "https://nextyn.com/static/media/main_logo.5691095e.svg",
      },
    },
    datePublished: { timeStamp },
    dateModified: { timeStamp },
  };

  return (
    <div>
      <Helmet>
        <title className="next-head">
          {blogs[getQueryVariable("blogId")].title}
        </title>
        <meta
          property="og:title"
          content={blogs[getQueryVariable("blogId")].title}
          className="next-head"
        />
        <meta
          name="description"
          content={blogs[getQueryVariable("blogId")].meta_desc}
          data-react-helmet="true"
        />
        <meta
          property="og:url"
          content={blogs[getQueryVariable("blogId")].url}
          class="next-head"
        ></meta>
      </Helmet>
      <script type="application/ld+json">
        {JSON.stringify(articleStructured)}
      </script>
      <Header />
      <section className="singleBlog mt-5">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-12 col-xxl-10">
              <Link className="singleLink" to={"/news-and-insights"}>
                <h4>
                  <LeftOutlined />
                  Back to News & Insights
                </h4>
              </Link>
              {/* <div className='mt-5' style={{ borderRadius: '5px' }}>
                                <img src={singleNewsInsightImg} alt="Single Article" className='width100' />
                            </div> */}
              <h1 className="mt-100 colord-heading">
                {blogs[getQueryVariable("blogId")].title}
              </h1>
              <div className="d-flex align-items-center mt-100">
                {blogs[getQueryVariable("blogId")]?.tags?.map((row) => (
                  <>
                    <div className="article_tags">{row}</div>
                  </>
                ))}
                <div className="timeStamp">
                  at {blogs[getQueryVariable("blogId")].timeStamp}
                </div>
              </div>
            </div>
            <div className="col-12 col-xxl-10">
              <div className="mt-100">
                {blogs[getQueryVariable("blogId")].description.map((row) => (
                  <>
                    {row?.head && <h5>{row.head}</h5>}
                    {row?.para && parse(row.para)}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="newsInsights mt-100">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-12 col-xxl-12">
              <div className="d-flex align-items-center justify-content-between mb-5">
                <div>
                  <h1>Popular Insights</h1>
                </div>
                <div style={{ display: "inline-block" }}>
                  <svg
                    onClick={() => swiper?.slidePrev()}
                    width="39"
                    height="19"
                    viewBox="0 0 39 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "20px",
                      cursor: slideBeginning ? "not-allowed" : "pointer",
                    }}
                  >
                    <path
                      opacity={slideBeginning ? "0.4" : ""}
                      d="M2.02641 9.5272L38.6562 9.5272M10.2537 18L2 9.5L10.2537 1"
                      stroke="black"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                  </svg>

                  <svg
                    width="39"
                    height="19"
                    viewBox="0 0 39 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => swiper?.slideNext()}
                    style={{ cursor: slideEnd ? "not-allowed" : "pointer" }}
                  >
                    <path
                      opacity={slideEnd ? "0.4" : "" ? "0.4" : ""}
                      d="M37.1298 9.5272L0.5 9.5272M28.9025 18L37.1563 9.5L28.9025 1"
                      stroke="black"
                      stroke-width="2"
                      stroke-miterlimit="10"
                    />
                  </svg>
                </div>
              </div>
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                onSlideChange={() => changeSlide()}
                onSwiper={(swiper) => setSwiper(swiper)}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {blogs.map((row, key) => (
                  <>
                    {key != getQueryVariable("blogId") ? (
                      <>
                        <SwiperSlide>
                          <Insights
                            img={row.img}
                            defaultKey={row.url}
                            title={row.title}
                            subHead={row.subHead}
                            tags={row.tags}
                            timeStamp={row.timeStamp}
                          />
                        </SwiperSlide>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="contactUs mt-100">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-12 col-xxl-12">
              <div className="pos-relative">
                <img src={nextynBg} className="width100" alt="bg" />
                <div className="nextynBg">
                  <h1 className="mb-3">Big enough to deliver,</h1>
                  <h1 className="mb-5">small enough to care</h1>
                  <a>
                    <button className="btn getInTouch">Get in touch</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default SingleNewsAndInsight;
