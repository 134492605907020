import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import nextynBg from '../assets/img/nextyn_bg.png';
import { Link } from 'react-router-dom'
import HelmetComponent from '../utils/HelmetComponent';
function TermsAndUse() {
    return (
        <div>
            <HelmetComponent title={'Nextyn | Terms and uses'} desc={'These Terms of Engagement (the “Agreement”) are entered into between you (“Expert”) and Nextyn and its subsidiaries or affiliates (“Nextyn”, “we”, “us”, “our”) and, together with our Privacy Policy, govern your relationship with Nextyn and your participation in Interchanges as defined below. Acceptance of these Terms of Engagement is a prerequisite to your participation in any Interchange with a Client.”).'} />
            <Header />
            <section className='mt-100'>
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className='text-center'>
                                <h1 className="colord-heading text-large">Terms and Use</h1>
                            </div>
                            <div className='mt-100 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Terms of Engagement</h4>
                                <p>These Terms of Engagement (the “Agreement”) are entered into between you (“Expert”) and
                                    Nextyn and its subsidiaries or affiliates (“Nextyn”, “we”, “us”, “our”) and, together with our Privacy
                                    Policy, govern your relationship with Nextyn and your participation in Interchanges as defined below.
                                    Acceptance of these Terms of Engagement is a prerequisite to your participation in any Interchange
                                    with a Client.</p>
                            </div>
                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Definitions</h4>
                                <p><strong>“Nextyn”</strong> means a brand under the registered company of Nextyn Advisory Private Limited, having
                                    its registered office in Mumbai, India</p>

                                <p><strong>“Client”</strong> means any organisation and their designated contacts and representatives you may interact
                                    with in the context of an Interchange in accordance with the terms of this Agreement.</p>

                                <p><strong>“Interchange”</strong> means a professional exchange involving Client(s) and Expert(s) which may be shortor long-term in nature and take the form of a remote consultation (telephone, email or other form of
                                    electronic messaging), in-person consultation, information provision in the form of reports and
                                    surveys, or advisory or employment relationships with Client or Client’s affiliate(s).</p>

                                <p><strong>“Mandate Scope”</strong> means a description of the subject matter to be covered in the context of an
                                    Interchange.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>General Eligibility</h4>
                                <p>Our Clients need to be able to rely on the fact that, in participating in an Interchange with them, you
                                    are eligible to do so and are not violating any obligations you may owe to any third parties (e.g. your
                                    employer, former employer etc.). By accepting these Terms of Engagement, you are confirming that:
                                    (i) your participation as an individual is permissible, (ii) you have obtained all necessary and
                                    appropriate consents and waivers, and (iii) you will be acting as an independent contractor and not as
                                    a Nextyn employee, agent, representative, partner or joint venture.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Background Information</h4>
                                <p>You agree that any information you provide or confirm to Nextyn, including that relating to your
                                    career background, industries of expertise and your personal data (“Background Information”) will be
                                    true, accurate and not misleading. In particular, you confirm and agree that you will inform us of any
                                    inaccuracies or omissions in your career history summary.</p>
                                <p>You further agree that Nextyn, in its absolute discretion, may share some or all of such Background
                                    Information with Client(s) to enable them to schedule a potential Interchange with you.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Commitments before an Interchange</h4>
                                <p>Nextyn may contact you regarding your possible participation in Interchange(s). Discussing a
                                    particular Mandate Scope with you merely constitutes an attempt at determining your potential
                                    suitability and Nextyn cannot guarantee you will be contacted again or indeed will engage in an
                                    Interchange with our Client as our Clients determine with whom they wish to engage in Interchanges
                                    at their sole discretion.</p>
                                <p>Upon learning of a given Mandate Scope, it is your responsibility to determine whether participating
                                    in a prospective Interchange given the particular Mandate Scope is appropriate. Participation in any
                                    Interchange is always at your discretion and you may decline to participate without reason. You
                                    should categorically decline participation in any Interchange if:</p>
                                <ul>
                                    <li><p>you know that by participating you are violating any law or regulation or breaching any
                                        agreement with or obligation towards a third party (e.g person, employer, former employer or
                                        other);</p></li>
                                    <li><p>your participation in the Interchange would give rise to any professional, regulatory or ethical
                                        conflict, or would result in a breach of a duty of confidentiality to which you are subject;</p></li>
                                    <li><p>you are a director, officer or other employee of a company or organisation that has made or is
                                        subject to a tender offer or takeover offer (or equivalent process in any jurisdiction), or you are
                                        employed by an entity that has acted on behalf of a company in connection with such tender
                                        offer or takeover offer. For these purposes a ‘tender offer’ shall mean an offer to purchase some
                                        or all of the shares of any public company</p></li>
                                    <li><p>in a personal capacity, you have ever been convicted of a felony, been the subject of sanctions
                                        administered by any jurisdiction, been the subject of an investigation by a securities regulator or other regulatory authority or been a defendant in any proceeding where fraud or violation of laws
                                        or regulations is alleged. You confirm that you have never been subject to any such convictions,
                                        sanctions, investigations or proceedings; or</p></li>

                                    <li><p>you are a serving member of the armed forces of any country.</p></li>
                                </ul>

                                <p>Provided that you have determined that it is appropriate to participate in a prospective Interchange
                                    given the particular Mandate Scope, and our Client has requested Nextyn to schedule a Consultation,
                                    we will contact you to determine your availability and share with you our Client’s identity. At this
                                    point, if you believe the Client to be a competitor of your employer or the entity on whose board you
                                    serve or there to be any other conflict of interest, you must decline to participate.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Commitments during an Interchange</h4>
                                <p>You agree that, while participating in an Interchange:</p>
                                <ul>
                                    <li><p>you will not discuss or disclose information that is relatable to a company of which you are an
                                        officer, director or employee;</p></li>
                                    <li><p>you will not disclose, and your participation in the Interchange will not otherwise result in the
                                        disclosure of, any:</p></li>
                                    <li><p>inside information or other non-public information concerning a company whose securities are
                                        traded on a recognised exchange or a quoted instrument;</p></li>
                                    <li><p>trade secret; or</p></li>
                                    <li><p>other information that you have a duty to keep confidential, or that you have obtained from any
                                        person who expects you to keep such information confidential, or could reasonably expect to be
                                        considered confidential;</p></li>
                                    <li><p>you will not provide investment, financial, accountancy, legal, medical or other professional
                                        advice to any Client in the context of the Interchange. For the avoidance of doubt, we do not
                                        consider your general industry perspective, opinions and insights to constitute advice;</p></li>
                                    <li><p>if you are an auditor or a former auditor, you will not comment on companies or organisations
                                        you currently audit or have audited in the last 5 years;</p></li>
                                    <li><p>if you have worked in the accounting and finance department of any company within the last 12
                                        months, you will not discuss accounting or financial issues relating to that company or that
                                        company’s affiliates;</p></li>
                                    <li><p>if you currently serve as a government official in any jurisdiction, you will not discuss any
                                        government legislation, government regulation, government policy or government business that
                                        you are in a position to influence or vote upon;</p></li>
                                    <li><p>you will not disclose any material non-public or other confidential information relating to a live
                                        clinical trial in which you are involved; and</p></li>
                                    <li><p>you will comply with all applicable laws, statutes, regulations and codes relating to anti-bribery
                                        and anti-corruption, including, without limitation, The Prevention of Corruption Act, 1988
                                        (India) along with its amendments or any other law prohibiting corruption and bribery in a
                                        country other than India (“Anti-Corruption Laws”).</p></li>
                                    <li><p>you will share appropriate information with the client during an Interchange. If the client feels
                                        that you do not have appropriate information/expertise on the matter of discussion, the client
                                        has the right to discontinue the consultation within the first 10 minutes. In such case, you are not
                                        entitled to be compensated for the consultation. </p></li>
                                </ul>
                                <p>If during an Interchange, you determine any professional, regulatory or ethical conflict, or if you
                                    identify any other reason why you may not or should not continue to participate in the Interchange,
                                    you should discontinue your participation in the Interchange and notify Nextyn immediately</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Commitments after an Interchange</h4>
                                <p>Client relationships for Nextyn are an important asset to the Company. Accordingly, you agree,
                                    following your introduction to any Client by Nextyn, that:</p>
                                <p>a) unless you have a consulting, employment or other business relationship (“Business Relationship”)
                                    with such Client that predates the introduction, you will not knowingly solicit from or propose to any
                                    such Client any kind of Business Relationship without such Client’s express consent; and</p>
                                <p>b) you will in any event promptly notify us in writing of any Business Relationship with a Client that
                                    takes place within one year of your initial introduction</p>
                                <p>You agree to provide all reasonable assistance to Nextyn and our Client in responding to any
                                    regulatory enquiries relating to any Interchange in which you are involved</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Invoicing & Payment </h4>
                                <p>Once the consultation has been completed, you shall raise an invoice to us within 7 working days for
                                    the same. The invoice shall be as per the pre-decided consulting rate for the assignment and shall be
                                    inclusive of all Government or local taxes, as applicable in your jurisdiction. The invoice must be
                                    emailed to info@nextyn.com and must consist of your bank details for us to successfully remit the
                                    fee. </p>
                                <p>The fee shall be remitted to your account, no more than 30 days after successfully completing the
                                    consultation.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Confidentiality and Proprietary Information </h4>
                                <p>By participating in an Interchange, certain information (“Proprietary Information”), including but not
                                    limited to (i) the existence or subject matter of the Interchange(s), (ii) the identity and details relating
                                    to the Client, and (iii) any other confidential information or intellectual property of Nextyn or its
                                    Client (including information relating to any actual or potential investment, trading and/or any
                                    business decisions of Client), may be disclosed to you or become known to you. You agree not to
                                    disclose any Proprietary Information to any third party other than the party that disclosed it to you,
                                    or to use or benefit from any Proprietary Information (including by dealing in the securities of any
                                    company to which Proprietary Information relates or encouraging any other person to do so). For
                                    the avoidance of doubt, you may not publicise or market your participation in Interchange(s) or your
                                    relationship with Nextyn.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Communication with Third Parties </h4>
                                <p>If you speak with any third person prior to or following an Interchange, you agree (i) to comply with
                                    your obligations relating to Proprietary Information at all times, (ii) not to compensate such person in
                                    any way for information, and (iii) to disclose clearly that you are acting on behalf of a third party
                                    (without identifying our Client). In the context of participating in Interchange(s) with our Client(s),
                                    you should neither before nor after an Interchange, consult with your contacts at any company asking
                                    them for information about that company or about the scope of the Interchange</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Intellectual Property</h4>
                                <p>If, in connection with an Interchange, you provide a Client with any written documentation or any
                                    other written (or otherwise documented) material (including, without limitation, reports, data, studies,
                                    charts, specifications and programmes) (“Documentation”)</p>
                                <p>a) you irrevocably and unconditionally warrant and represent that you are the sole unencumbered
                                    author, owner or holder of an appropriate, valid and sufficient license of all rights, title and interest
                                    (including the intellectual property rights) in such Documentation;</p>
                                <p>b) you hereby grant (and you warrant that you have the right to grant) our Client a nonexclusive,
                                    irrevocable, perpetual, royalty-free, fully sub-licensable, fully paid up, worldwide license to use, copy,
                                    modify, delete and adapt the Documentation; and</p>
                                <p>c) you indemnify and agree to keep indemnified Nextyn, its Clients and any agent, service provider or
                                    consultant appointed by either Nextyn or its Clients to participate in, and conduct, an Interchange,
                                    against any and all liabilities in connection with claims or proceedings based on the claim that the
                                    Documentation infringes any intellectual property rights of yours or a third party.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Term & Termination</h4>
                                <p>These Terms of Engagement come into force on the date of your acceptance and will continue to be
                                    in force until terminated by either party at any time upon giving written notice to the other. Following
                                    termination, you will continue to be bound by your obligations listed under Your Responsibilities
                                    after an Interchange, Confidentiality and Proprietary Information, Communication with Third
                                    Parties, Intellectual Property and Third Parties.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Liability</h4>
                                <p>We shall under no circumstances whatever be liable to you, whether in contract, tort (including
                                    negligence), breach of statutory duty or otherwise, for any direct loss or any indirect or consequential
                                    loss or damage of any kind arising under or in connection with these Terms of Engagement
                                    PROVIDED THAT nothing in this Agreement shall limit or exclude (or purport to limit or exclude)
                                    our liability for death or personal injury caused by its negligence, or the negligence of its employees,
                                    agents or subcontractors and fraud or fraudulent misrepresentation.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Third Parties</h4>
                                <p>You agree that these Terms of Engagement are intended to benefit Clients and enable them to satisfy
                                    themselves that all Interchanges occur in accordance with the Terms of Engagement, applicable laws
                                    or regulations and will not lead to the disclosure of restricted information, including inside and/or
                                    material non-public information. You further agree that Clients may rely on and enforce these Terms
                                    of Engagement. Apart from you, Nextyn and our Clients, no third party is entitled to rely on or
                                    enforce these Terms of Engagement.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Additional Terms & Conditions</h4>
                                <p>If any term, condition, or provision of these Terms of Engagement (or part thereof) is determined to
                                    be unlawful, invalid, void, or for any reason unenforceable, the validity and enforceability of the
                                    remaining terms, conditions and provisions (or parts thereof) shall not in any way be affected or
                                    impaired.</p>
                                <p>We may provide a translation of these Terms of Engagement for reference purposes. For the
                                    avoidance of doubt, in the event of any inconsistency between the English version of the Terms of
                                    Engagement and a translation provided to you, the terms of the English version of the Terms of
                                    Engagement shall always prevail.</p>

                                <p>These Terms of Engagement are governed by and shall be construed in accordance with the Indian
                                    Law. The parties hereby submit to the exclusive jurisdiction of the Indian courts in relation to any
                                    claims or other matters arising from or related hereto, whether in tort, contract or otherwise.</p>

                                <p>These Terms of Engagement constitute the entire agreement between you and us relating to the
                                    subject matter herein. In the event that there is a conflict between these Terms of Engagement and
                                    any separate acknowledgements and/or undertakings given by you to Nextyn in connection with any
                                    Interchange(s), these Terms of Engagement will prevail. Any amendment to this Agreement must be
                                    in writing, accepted by both parties and expressed to constitute an amendment to these Terms of
                                    Engagement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contactUs mt-100">
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className="pos-relative">
                                <img src={nextynBg} className="width100" alt="bg" />
                                <div className="nextynBg">
                                    <h1 className="mb-3">Big enough to deliver,</h1>
                                    <h1 className="mb-5">small enough to care</h1>
                                    <Link to={'/contact-us'}><button className="btn getInTouch">Get in touch</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default TermsAndUse