import { Col, Row, Slider } from "antd";
import React, { useState } from "react";
import "../App.css";

const marks = {
  0: "$0",
  500: "$500",
  1000: "$1000",
  1500: "$1500",
};

const step = 10;

const formatter = (value) => `$${value}`;

// const style = {
//     display: 'inline-block',
//     height: 300,
//     marginLeft: 70,
//   };

const RangeSlider = ({ setRange, range, inputValues, count, clicked }) => {
  const [disabled, setDisabled] = useState(true);

  const handleSliderChange = (value) => {
    setRange(value);
  };

  return (
    <>
      <div className="d-flex justify-content-center  text-center mt-4 ">
        <div>
          <h4
            className="colord-heading content-fit mb-5"
            style={{ fontWeight: "400" }}
          >
            Mean Expert Hourly Consulting Rate
          </h4>
          <div style={{ marginTop: "20px" }}>
            <Slider
              range
              min={0}
              max={1500}
              step={10}
              marks={marks}
              value={range}
              disabled={true}
              onChange={handleSliderChange}
              style={{ width: "65rem" }}
              tooltip={{
                open: true,
                formatter,
              }}
            />
          </div>
        </div>

        {/* <h4 style={{ fontWeight: "400" }}>
          Total Expert’s Matching your requirement
        </h4> */}
      </div>

      <div className="mt-5  d-flex justify-content-center align-items-center ">
        <div>
          <div
            className="pos-relative text-center"
            style={{ marginBottom: "1.5rem" }}
          >
            <a href={"https://cloud.nextyn.com/demo"}>
              <button
                className="btn filled-btn register_wrapper text-center"
                id="register_wrapper"
                style={{ zIndex: "100" }}
              >
                Request a Free Trial
              </button>
            </a>
          </div>
          {clicked ? (
            <>
              <div className="mt-5 ">
                <h3
                  className="colord-heading content-fit"
                  style={{ fontWeight: "400" }}
                >
                  Experts matching your requirements, on our cloud
                </h3>
                <div className="site-card-wrapper mt-5">
                  <Row
                    className="d-flex justify-content-center align-items-center"
                    gutter={16}
                  >
                    <Col
                      span={8}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <div
                        className="counter-box"
                        style={{
                          fontSize: "45px",
                          color: "#5746ec",
                          fontWeight: "600",
                        }}
                      >
                        <span className="counter">{count}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default RangeSlider;
