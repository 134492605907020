import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import config from "../API/Config";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

function ContactUs() {
  let timeout;
  let scroll1 = 0;
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [desc, setDesc] = useState();
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const handlePhoneChange = (value, country) => {
    console.log(value, "ctnumber");
    console.log("Received dial code:", country?.dialCode);
    console.log("Country options:");

    setPhone(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    console.log("Selected country:", selectedCountry);

    const contact_code = country?.dialCode;
    const contact_number = value?.replace(country?.dialCode, "");

    const isValidContact = contact_code && contact_number;

    if (isValidContact) {
      const fullContact = `${contact_code}${contact_number}`;
      console.log("Full contact:", fullContact);

      setPhone(fullContact);
      setCountry(country?.name);

      // setContactValid(isValidContact);
    } else {
      console.log("No matching country found for dial code:", country.dialCode);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const confHeader = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const confBody = JSON.stringify({
      name: name,
      email: email,
      messages: desc,
      phone: phone,
      country: country,
    });

    axios
      .post(
        `https://api.platform.nextyn.com/project/create-enquiry`,
        confBody,
        confHeader
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          enqueueSnackbar("Form Submitted successfully", {
            variant: "success",
            preventDuplicate: true,
          });
          setName("");
          setEmail("");
          setDesc("");
          setCountry("");
          setPhone("");
        } else {
          enqueueSnackbar(res.data.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    window.onscroll = () => {
      scroll1 = window.scrollY;
      timeout = setTimeout(() => {
        if (scroll1 >= 150) {
          document.getElementById("header").classList.add("sticky");
        } else {
          document.getElementById("header").classList.remove("sticky");
        }
      }, 10);
    };
  }, []);

  const checkEmail = (value) => {
    if (
      value.includes("@gmail.com") ||
      value.includes("@yahoo.com") ||
      value.includes("@hotmail.com") ||
      value.includes("@rediffmail.com")
    ) {
      setEmail("");
      enqueueSnackbar(`${value} email is not accepted`, {
        variant: "error",
        preventDuplicate: true,
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Nextyn | Contact Us</title>
        <meta
          name="description"
          content={
            "Get in touch with us to learn more about our services, ask for assistance with a technical difficulty, or if you would like a product demo."
          }
        />
        <meta name="robots" content="index, follow" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-613917167"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-613917167');
          `}
        </script>
      </Helmet>
      <Header />
      <section className="contactUs mt-100">
        <div className="container-lg">
          <div className="row justify-content-center">
            <div className="col-12 col-xxl-12">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div style={{ maxWidth: "450px" }}>
                    <h1 className="colord-heading text-large">Contact Us</h1>
                    <p
                      style={{ fontWeight: "200", fontSize: "18px" }}
                      className="mt-5"
                    >
                      Get in touch with us to learn more about our services, ask
                      for assistance with a technical difficulty, or if you
                      would like a product demo.
                    </p>
                    <p style={{ fontWeight: "200", fontSize: "18px" }}>
                      Our support and sales team will be glad to assist.
                    </p>

                    <div className="mt-100">
                      <div className="d-flex align-items-center mb-3">
                        <svg
                          width="24"
                          height="20"
                          viewBox="0 0 24 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.9995 11C10.049 11 0 2.891 0 2.891V2C0 0.896 0.894844 0 1.99942 0H21.9996C23.1052 0 24 0.896 24 2L23.984 3C23.984 3 14.0439 11 11.9995 11ZM11.9995 13.75C14.1377 13.75 23.984 6 23.984 6L24 18C24 19.104 23.1052 20 21.9996 20H1.99942C0.895843 20 0 19.104 0 18L0.0159794 6C0.0149806 6 10.049 13.75 11.9995 13.75Z"
                            fill="#312E2E"
                          />
                        </svg>
                        <a
                          href="mailto:info@nextyn.com"
                          className="mb-0 fs-18"
                          style={{ marginLeft: "50px", color: "#312E2E" }}
                        >
                          info@nextyn.com
                        </a>
                      </div>
                    </div>

                    <div
                      className="mt-5"
                      style={{ borderTop: "1px solid rgba(0,0,0,0.1)" }}
                    >
                      <ul className="ps-0 social-logos">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/nextyn/"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="30"
                              height="30"
                            >
                              <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z" />
                            </svg>
                          </a>
                        </li>
                        <li style={{ marginLeft: "15px" }}>
                          <a
                            target="_blank"
                            href="https://twitter.com/NextynAdvisory"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="30"
                              height="30"
                            >
                              <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                            </svg>
                          </a>
                        </li>
                        <li style={{ marginLeft: "15px" }}>
                          <a
                            target="_blank"
                            href="https://instagram.com/nextynadvisory?igshid=YmMyMTA2M2Y="
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 50 50"
                              width="28px"
                              height="28px"
                            >
                              {" "}
                              <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12">
                        <h4>Our Offices:</h4>
                      </div>
                      <div className="col-12 col-lg-6 mt-4">
                        <h5>Singapore</h5>
                        <p style={{ fontWeight: "200" }}>
                          68 Circular Road, #02-01
                          <br />
                          049422, Singapore
                        </p>
                      </div>
                      <div className="col-12 col-lg-6 mt-4">
                        <h5>Mumbai</h5>
                        <p style={{ fontWeight: "200" }}>
                          Inizio 401, Cardinal Gracious Road, Andheri East,
                          Mumbai - 400059
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <form onSubmit={handleSubmit}>
                    <div className="formBox">
                      <div className="row">
                        <div className="col-12 form-group">
                          <label>Name*</label>
                          <div>
                            <input
                              className="form-control"
                              name="name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 form-group">
                          <label>Email*</label>
                          <div>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                checkEmail(e.target.value);
                              }}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 form-group">
                          <label>Contact*</label>
                          <div>
                            <PhoneInput
                              className="react-tel-input-2"
                              country={"us"}
                              enableSearch={true}
                              value={phone}
                              onChange={(value, country) => {
                                handlePhoneChange(value, country);
                              }}
                              inputStyle={{
                                width: "100%",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 form-group">
                          <label>Description*</label>
                          <div>
                            <textarea
                              className="form-control"
                              name="comment"
                              value={desc}
                              onChange={(e) => setDesc(e.target.value)}
                              required
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-12">
                          <button
                            className="btn filled-btn width100"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
}

export default ContactUs;
