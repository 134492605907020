import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import blogs from '../API/Insights';
import nextynBg from '../assets/img/nextyn_bg.png';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Insights from '../Components/Insights';
function NewsAndInsights() {
    let timeout;
    let scroll1 = 0;
    useEffect(() => {
        window.onscroll = () => {
            scroll1 = window.scrollY;
            timeout = setTimeout(() => {
                if (scroll1 >= 150) {
                    document.getElementById('header').classList.add('sticky')
                } else {
                    document.getElementById('header').classList.remove('sticky')
                }
            }, 10)
        }
    }, []);
    function truncate(str, length) {
        return str.length > length ? str.substring(0, length) + "..." : str;
    }
    return (
        <div>
            <Header />

            <section className="contactUs mt-100">
                <div className="container-lg">
                    <div className='row justify-content-center'>
                        <div className='col-12 col-xxl-12 text-center'>
                            <h1 className="colord-heading text-large">News & Insights</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="newsInsights mt-100">
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className='row'>
                                {blogs.map((row, key) => (
                                    <div className="col-12 col-md-2 col-lg-4 mb-5">
                                        <Insights
                                            img={row.img}
                                            defaultKey={row.url}
                                            title={row.title}
                                            subHead={row.subHead}
                                            tags={row.tags}
                                            timeStamp={row.timeStamp}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contactUs mt-100">
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className="pos-relative">
                                <img src={nextynBg} className="width100" alt="bg" />
                                <div className="nextynBg">
                                    <h1 className="mb-3">Big enough to deliver,</h1>
                                    <h1 className="mb-5">small enough to care</h1>
                                    <Link to={'/contact-us'}><button className="btn getInTouch">Get in touch</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default NewsAndInsights