import React from "react";
import { Link } from "react-router-dom";
import config from "../API/Config";
import nextyn20 from "../assets/img/Nextyn_Logo 155x42.svg";
import strategylogo from "../assets/img/nextyn_strategylogo.png";

function Footer() {
  return (
    <footer>
      <div className="container-lg">
        <div className="row justify-content-center">
          <div className="col-12 col-xxl-12">
            <div className="row">
              <div className="col-12 col-md-5">
                <img alt="nextyn_logo" className="header_logo" src={nextyn20} />
                <p
                  className="mt-2 mb-2"
                  style={{ fontSize: "20px", fontWeight: "200" }}
                >
                  Search. Consult. Collaborate
                </p>

                <ul className="ps-0 social-logos" style={{ marginTop: "0px" }}>
                  <li style={{ display: "inline-block" }}>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/nextyn/"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="30"
                        height="30"
                      >
                        <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 0 1-1.548-1.549 1.548 1.548 0 1 1 1.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z" />
                      </svg>
                    </a>
                  </li>
                  <li style={{ display: "inline-block", marginLeft: "25px" }}>
                    <a
                      target="_blank"
                      href="https://twitter.com/NextynAdvisory"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="30"
                        height="30"
                      >
                        <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                      </svg>
                    </a>
                  </li>
                  <li style={{ display: "inline-block", marginLeft: "25px" }}>
                    <a
                      target="_blank"
                      href="https://instagram.com/nextynadvisory?igshid=YmMyMTA2M2Y="
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="28px"
                        height="28px"
                      >
                        {" "}
                        <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col">
                <h4>Account</h4>
                <ul>
                  <li>
                    <a className="footNav" href={"https://cloud.nextyn.com"}>
                      Client
                    </a>
                    {/* <a
                      className="footNav"
                      href={config.dashboardUrl + "/client-login"}
                    >
                      Client
                    </a> */}
                  </li>
                  <li>
                    <a
                      className="footNav"
                      href={config.dashboardUrl + "/expert-login"}
                    >
                      Expert
                    </a>
                  </li>
                  <li>
                    <a
                      className="footNav"
                      href={config.dashboardUrl + "/admin-login"}
                    >
                      Employee
                    </a>
                  </li>
                  {/* <li><a className="footNav" href={config.dashboardUrl + '/client-signup'}>Client Signup</a></li>
                                    <li><a className="footNav" href={config.dashboardUrl + '/expert-register'}>Expert Signup</a></li> */}
                </ul>
              </div>

              <div className="col">
                <h4>Company</h4>
                <ul>
                  <li>
                    <Link to={"/privacy-policy"} className="footNav">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={"/terms-and-use"} className="footNav">
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link to={"/compliance"} className="footNav">
                      Compliance
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col">
                <h4>Support</h4>
                <ul>
                  <li>
                    <Link className="footNav" to={"/contact-us"}>
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link className="footNav" to={"/faqs"}>
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-12 col-md-5">
                <a
                  href="https://nextynstrategy.com/"
                  className="cursor-pointer"
                >
                  <img
                    alt="nextyn_logo"
                    className="header_logo"
                    src={strategylogo}
                    style={{ maxHeight: "56%", maxWidth: "56%" }}
                  />
                </a>
              </div>
              <div className="col">
                <h4>Our Offices:</h4>
              </div>
              <div className="col">
                <h4>Singapore</h4>
                <p style={{ fontSize: "15px", fontWeight: "200" }}>
                  68 Circular Road, #02-01
                  <br />
                  049422, Singapore
                </p>
              </div>

              <div className="col">
                <h4>Mumbai</h4>
                <p style={{ fontSize: "15px", fontWeight: "200" }}>
                  Inizio 401, Cardinal Gracious Road, Andheri East, Mumbai -
                  400059
                </p>
              </div>
            </div>
            <div className="bottomFooter text-center">
              Copyright © 2023 Nextyn Advisory Private Limited
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
