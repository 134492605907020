const ENV = {
  // BASE_URL: "http://localhost:8081/api",
  // Production
  BASE_URL: "https://api.platform.nextyn.com",
  SQL_HOST: "",

  // development
  // BASE_URL: "https://dev.api.cloud.nextyn.com:8081/api",
  // SQL_HOST: "https://dev.api.expertcloud.nextyn.com/app",
};

export default ENV;
