import React, { useEffect } from "react";
import Header from "../Components/Header";
import nextynBg from "../assets/img/nextyn_bg.png";
import { Tabs } from "antd";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import config from "../API/Config";
import HelmetComponent from "../utils/HelmetComponent";
function Faq() {
  const { TabPane } = Tabs;
  let timeout;
  let scroll1 = 0;
  useEffect(() => {
    window.onscroll = () => {
      scroll1 = window.scrollY;
      timeout = setTimeout(() => {
        if (scroll1 >= 150) {
          document.getElementById("header").classList.add("sticky");
        } else {
          document.getElementById("header").classList.remove("sticky");
        }
      }, 10);
    };
  }, []);
  return (
    <>
      <div>
        <HelmetComponent
          title={"Nextyn | Faqs"}
          desc={
            "Nextyn's Expert Cloud is a closed sourced expert network, connecting clients with our panel of experts over 60 minute micro-consulting calls. On Nextyn's Expert Cloud platform, clients can search for experts, consult and get access to call recordings and transcripts along with a platform to collaborate with their teams internally on projects."
          }
        />
        <Header />
        <section className="contactUs mt-100">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-12 text-center">
                <h1 className="colord-heading text-large">
                  Frequently Asked Questions
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="container-lg">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xxl-12">
                                <div className="pos-relative">
                                    <img src={nextynBg} className="width100" alt="bg" />
                                    <div className="nextynBg">
                                        <h1 className="mb-5">FAQs</h1>
                                        <Link to={'/contact-us'}><button className="btn getInTouch">Get in touch</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </section>

        <section className="faqs m-t-120">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-12">
                <Tabs defaultActiveKey="1" centered>
                  <TabPane tab="For Experts" key="1">
                    <div className="row">
                      <div className="col-12 col-md-12 mt-5">
                        <div>
                          <h5 className="question mb-3">
                            What is Nextyn's Expert Cloud?
                          </h5>
                          <p className="answers">
                            Nextyn's Expert Cloud is a closed sourced expert
                            marketplace, connecting clients with our panel of
                            experts over 60 minute micro-consulting calls.
                          </p>
                          <p className="answers mb-5">
                            On Nextyn's Expert Cloud platform, clients can
                            search for experts, consult and get access to call
                            recordings and transcripts along with a platform to
                            collaborate with their teams internally on projects.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            Who are Nextyn's clients and where are they located
                            ?
                          </h5>
                          <p className="answers">
                            Nextyn's clients are largely firms that operate in
                            the space of Management Consulting, Private
                            Equity/Venture Capital, Public Equities, Corporates,
                            Industrials and Non Government Organisations.{" "}
                          </p>
                          <p className="answers mb-5">
                            Nextyn's current portfolio of clients are based in
                            over 30 countries, globally.
                          </p>
                        </div>

                        <div>
                          <h5 className="question mb-3">
                            How do I register on Nextyn’s panel of Experts?
                          </h5>
                          <p className="answers">
                            Experts can register by submitting their LinkedIn
                            URLs. Alternatively, our registration process can
                            also be completed manually by inputting your
                            detailed credentials.
                          </p>
                          <p className="answers mb-5">
                            To register,{" "}
                            <a
                              className=""
                              href={config.dashboardUrl + "/expert-register"}
                            >
                              please click here
                            </a>
                            .
                          </p>
                        </div>

                        <div>
                          <h5 className="question mb-3">
                            How do I determine my hourly consulting rate?
                          </h5>
                          <p className="answers">
                            At Nextyn, we encourage our experts to define their
                            hourly consulting rates on the platform based on
                            their years of experience. Please note, keeping your
                            hourly consulting rate competitive ensures you get
                            invited to multiple consulting opportunities through
                            the year.{" "}
                          </p>
                          <p className="answers mb-5">
                            In 2021, the average hourly rate for an expert was
                            $150. However, there is no upper or lower limit on
                            hourly consulting rates for Experts.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            What happens once I've registered as an Expert on
                            the platform?
                          </h5>
                          <p className="answers">
                            Once you’ve successfully registered and accepted our
                            terms & conditions, our clients will be able to view
                            your profile background and request your time for a
                            potential consulting engagement.
                          </p>
                          <p className="answers mb-5">
                            In the case of a custom request from a client, our
                            team of analysts will touch base with you over email
                            to coordinate a successful consultation.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            Is there a fee to join Nextyn's panel of experts?
                          </h5>
                          <p className="answers">
                            Neither is there a joining fee, nor any other fee
                            associated with Nextyn’s platform for our panel of
                            experts.
                          </p>
                          <p className="answers mb-5">
                            The platform is free to join, participate in
                            micro-consulting conversations, and earn an hourly
                            consulting fee for your time and expertise.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            How do you define the term "Expert"?
                          </h5>
                          <p className="answers">
                            Experts are individuals with exemplary achievements
                            or expertise in their field of service. This could
                            be through a significant number of years spent in a
                            particular industry; leading teams, holding patents,
                            or possessing deep knowledge or a skill in a
                            particular field.{" "}
                          </p>
                          <p className="answers mb-5">
                            Nextyn’s panel of experts come from a diverse range
                            of over 30 industries and 90+ geographies. Being
                            sector agnostic, Nextyn aims to build its reach in
                            every industry and every geography across the globe.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            What duration do consultations usually last for?
                          </h5>
                          <p className="answers">
                            A micro-consulting call typically lasts for a
                            duration of 60 minutes. However, clients can in
                            advance request for a 30 minute consultation as
                            well.
                          </p>
                          <p className="answers">
                            Over and above the initial 60 minutes, consultation
                            fee is usually prorated in intervals of 15 minutes
                            each.{" "}
                          </p>
                          <p className="answers mb-5">
                            Please note, some clients prefer consulting experts
                            for a longer period of time (1 -12 months) and in
                            some instances also offer full time employment
                            opportunities to our experts.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            Please highlight how you treat Confidential/Material
                            Non-Public Information
                          </h5>
                          <p className="answers">
                            At Nextyn, compliance is our utmost priority and
                            prior to every consultation, both our clients and
                            experts are duly required to read, agree and sign on
                            our Terms & Conditions which explicitly highlight
                            what is and is not considered as
                            Confidential/Material Non-Public Information.
                          </p>
                          <p className="answers mb-5">
                            Please refer to our Term & Conditions & Compliance
                            documents for the same.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            What are your payment modes and timelines?
                          </h5>
                          <p className="answers">
                            At Nextyn, we provide four payment options: Wire
                            transfer/Bank Transfer, PayPal, Amazon Gift Card
                            and/or Donate to a Charity of your choice.
                          </p>
                          <p className="answers">
                            Your payment method can be chosen on your expert
                            dashboard, by logging in and clicking on “Add
                            Payment Method”.
                          </p>
                          <p className="answers mb-5">
                            Once the payment method is successfully added,
                            please expect the consulting fee to be processed
                            within 10 business days.
                          </p>
                        </div>
                        <div>
                          <h5 className="question mb-3">
                            How do I unregister myself from the platform?
                          </h5>
                          <p className="answers">
                            Please drop us a de-registration request on{" "}
                            <a href="mailto:info@nextyn.com">info@nextyn.com</a>{" "}
                            and our team will have your profile deactivated from
                            our platform.
                          </p>
                          <p className="answers mb-5">
                            Please note, once your account is deactivated, you
                            will not be contacted for or be able to participate
                            in any consultations with Nextyn.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="For Clients" key="2">
                    <div className="row">
                      <div className="col-12 mt-5">
                        <div className="mb-5">
                          <h5 className="question mb-3">
                            How is Nextyn different from other Expert Networks?
                          </h5>
                          <p className="answers">
                            Nextyn’s Expert Cloud is a closed source expert
                            network, offering you exclusive access to our custom
                            panel of experts from over 90 countries and 30+
                            industries.{" "}
                          </p>
                          <p className="answers">
                            Our platform prides itself on three key features:
                            Search, Consult & Collaborate
                          </p>
                          <p className="answers">
                            <strong>Search:</strong> Search function for our
                            panel of Experts through our advanced NLP based
                            algorithm.
                          </p>
                          <p className="answers">
                            <strong>Consult:</strong> Consult experts at your
                            convenience and access recordings & transcripts for
                            your conversations.
                          </p>
                          <p className="answers">
                            <strong>Collaborate:</strong> Access and share all
                            data gained from primary interactions on our
                            platform through our proprietary knowledge
                            management system.
                          </p>
                          <p className="answers">Some other key features:</p>
                          <ol type="number">
                            <li>
                              <p>
                                <strong>Flexible Pricing:</strong> Our hourly
                                consulting rates start at $450.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>No upfront fees:</strong> Access our
                                panel without committing a hefty upfront fee
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Custom Recruitment:</strong> For clients
                                with specific requests, our team of analysts
                                custom recruit experts within 36 hours
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Recording & Transcription:</strong> Get
                                access to call recordings & transcripts at no
                                additional cost, post the consultation{" "}
                              </p>
                            </li>
                          </ol>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Do you have an upfront fee to be onboarded as a
                            client?
                          </h5>
                          <p className="answers">
                            No. Neither do we have an upfront fee, nor a
                            requirement to commit to a minimum number of calls
                            per year.{" "}
                          </p>
                          <p className="answers">
                            We offer our clients complete flexibility in
                            scheduling calls with our panel of experts. An
                            invoice is raised ONLY for calls completed
                            successfully.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            What is the breakdown of your panel by Industry and
                            Geographies?
                          </h5>
                          <p className="answers">
                            Nextyn’s panel is sector agnostic, which means we
                            have and/or custom recruit experts based on your
                            specific requirement.
                          </p>
                          <p className="answers">
                            Currently, the panel has experts from over 90
                            geographies and 30 industries. On an average, we
                            onboard 55 experts on a daily basis.{" "}
                          </p>
                          <p className="answers">
                            In addition to the same, Nextyn has integrated
                            access to over 220 million professional profiles
                            through homogenised partners. Our technology and
                            experienced team of analysts specialise in
                            recruiting, qualifying and presenting some of the
                            most qualified professionals in the industry.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            How do I submit a custom request? How long does
                            Nextyn take to source custom experts?
                          </h5>
                          <p className="answers">
                            “Launch Project” - Our platform assists you to share
                            as much information for your custom requests. When
                            you launch a custom project, you can detail out
                            specifics such as Industry, Geography, Topics of
                            discussion and can also invite additional team
                            members from your Company to participate in viewing
                            and approving expert profiles.
                          </p>
                          <p className="answers">
                            A custom project request also allows you to
                            collaborate with the team at Nextyn through a live
                            chat function to discuss your requirements.
                          </p>
                          <p className="answers">
                            The average time taken to custom source and qualify
                            experts is 36 hours.{" "}
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            What are the typical hourly consulting rates of
                            Experts?
                          </h5>
                          <p className="answers">
                            Our consulting rates are flexible, which means,
                            prior to the consultation our clients are apprised
                            on the specific consulting rate of that particular
                            expert.{" "}
                          </p>
                          <p className="answers">
                            Hourly consulting rates start at $450.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Does Nextyn have a strong compliance framework?
                          </h5>
                          <p className="answers">
                            We pride ourselves on our compliance framework,
                            ensuring strict adherence on both clients as well
                            the experts front.{" "}
                          </p>
                          <p className="answers">
                            To read more about our Compliance policies, click
                            here.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            How long can an Expert consultation last?
                          </h5>
                          <p className="answers">
                            Expert consultations usually start at 60 minutes,
                            and are then billed in intervals of 15 minutes each
                            post the initial 60 minutes.{" "}
                          </p>
                          <p className="answers">
                            In case you would like to consult an expert for a
                            longer period of time (1 week - 12 months), please
                            contact us to discuss customised consulting services
                            and rates.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            How do you bill your clients?
                          </h5>
                          <p className="answers">
                            Clients are billed upon successfully completing a
                            micro-consulting call.
                          </p>
                          <p className="answers">
                            A consolidated invoice is raised on the earlier of
                            the following two occasions: End of the month or
                            completion of a said project.
                          </p>
                          <p className="answers">
                            <strong>Payment terms:</strong> 15 business days of
                            the invoice being raised.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Do you keep your clients' identity confidential?
                          </h5>
                          <p className="answers">
                            If the client wishes to keep their identity
                            confidential, it is mandatory to bring it to
                            Nextyn’s notice prior to the commencement of the
                            project, in writing.
                          </p>
                          <p className="answers">
                            However, in most cases, the team of analysts at
                            Nextyn will aim to inform and/or take permission
                            from the client before disclosing their name to the
                            Expert.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Can I engage an expert outside Nextyn's platform?
                          </h5>
                          <p className="answers">
                            Hourly consultations are only permitted through
                            Nextyn’s platform, upholding our integrity and
                            compliance processes set herewith.{" "}
                          </p>
                          <p className="answers">
                            In the instance a client wishes to engage with an
                            expert outside Nextyn’s platform, they can do so by
                            taking prior permission from the Company.
                          </p>
                          <p className="answers">
                            In such a situation, a finders fee of 10% of the
                            overall consulting fee paid to the Expert by the
                            Client in the first 365 days of engagement, will be
                            applicable.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            What if the expert profiles provided based on a
                            search request are not exactly what I was looking
                            for?
                          </h5>
                          <p className="answers">
                            We aim to search, thoroughly qualify and only
                            present Experts that match our specific client
                            requirements.{" "}
                          </p>
                          <p className="answers">
                            In the instance where an expert is not a perfect
                            match, the client has the right to conclude the
                            consultation within the first 10 minutes. In such a
                            situation, the consultation will not be billed and a
                            replacement profile for the same will be provided by
                            the team at Nextyn.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Does Nextyn have the capability to moderate expert
                            calls?
                          </h5>
                          <p className="answers">
                            Yes, Nextyn has a team of experienced in-house
                            moderators. In a situation where the client would
                            require a member of Nextyn’s team to moderate a
                            call, a $100/Hr fee over and above the Expert
                            consulting rate would be applied.
                          </p>
                          <p className="answers">
                            Post the call, a recording along with a transcript
                            of the conversation will be provided to the client.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Does Nextyn provide translators/translation services
                            during the Expert call, if the Expert does not speak
                            a familiar language?
                          </h5>
                          <p className="answers">
                            Yes, Nextyn has an experienced team of native
                            freelance translators, specialising in over 25
                            different languages. Our live translation services
                            are billed at $200/Hr.
                          </p>
                          <p className="answers">
                            Our clients are advised to put in a request in
                            writing, 24 hours before the scheduled consultation
                            for a translator.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Does Nextyn provide call records and transcripts? If
                            yes, how and where can I access them?
                          </h5>
                          <p className="answers">
                            Yes, Nextyn does provide call recordings and
                            transcripts post the consultation. This is at no
                            additional charge.
                          </p>
                          <p className="answers">
                            Recordings and transcripts can be accessed on your
                            knowledge management system, which is provided to
                            all our clients.
                          </p>
                        </div>

                        <div className="mb-5">
                          <h5 className="question mb-3">
                            Can I collaborate internally with my team and share
                            relevant recordings & transcripts with them?
                          </h5>
                          <p className="answers">
                            Yes, one of Nextyn’s key add-on features and
                            differentiating factors is our knowledge management
                            dashboard (part of the Expert Cloud platform).
                          </p>
                          <p className="answers">
                            This allows you to access, share, upload/download
                            and collaborate on Company wide consulting calls and
                            primary research findings of successfully completed
                            consultations.{" "}
                          </p>
                          <p className="answers">
                            If you would like to learn more, please get in touch
                            with our sales team, here.
                          </p>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
        <section className="contactUs mt-100">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-12">
                <div className="pos-relative">
                  <img src={nextynBg} className="width100" alt="bg" />
                  <div className="nextynBg">
                    <h1 className="mb-3">Big enough to deliver,</h1>
                    <h1 className="mb-5">small enough to care</h1>
                    <Link to={"/contact-us"}>
                      <button className="btn getInTouch">Get in touch</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Faq;
