import { RightOutlined } from "@ant-design/icons";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import searchImg from "../assets/img/animations/animation_500_l3h78r7k.gif";
import consult from "../assets/img/animations/animation_500_l3h7pqw4.gif";
import collabarate from "../assets/img/animations/animation_500_l3h8wzzi.gif";
import brand from "../assets/img/brands.svg";
import cloudBox from "../assets/img/expert_Cloud_bg.svg";
import mobBrand from "../assets/img/mob_client.svg";
import nextynBg from "../assets/img/nextyn_bg.png";
import Footer from "../Components/Footer";
import Header2 from "../Components/Header2";
// import accountant from '../assets/img/12.png'
// import accountant1 from '../assets/img/12-square.png'
import axios from "axios";
import { Helmet } from "react-helmet";
import "../App.css";
import transparent_logo from "../assets/img/expert-cloud-white.png";
import API_PATH from "../Constants/api-path";
import RangeSlider from "../pricingtool/RangeSlider";
import TopIndustry from "../pricingtool/TopIndustry";
import TopSelectionOption from "../pricingtool/TopSelectionOption";

function Home() {
  const [slideBeginning, setSlideBeginning] = useState(true);
  const [slideEnd, setSlideEnd] = useState(false);
  
  const [loading, setLoading] = useState("");
  const [experts, setExperts] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [currentPage, setCurrentPage] = useState(1);
  const [allDatas, setAlldatas] = useState(0);
  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [tags, setExpertise] = useState("");
  const [country, setCountry] = useState("");
  const [searchRgx, setSearchRgx] = useState("");

  const [count, setCount] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [range, setRange] = useState(0);
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedOption, setSelectedOption] = React.useState("");
  const [inputValues, setInputValues] = React.useState("");

  const dataSource = [
    { value: "0 to 2 years" },
    { value: "3 to 5 years" },
    { value: "6 to 10 years" },
    { value: "more than 10" },
  ];

  const generateRandomCount = () => {
    const randomCount = Math.floor(Math.random() * (5499 - 4000 + 1) + 4000);
    setCount(randomCount);
    setClicked(true);
  };

  const generateRandomCountTwo = () => {
    const randomCount = Math.floor(Math.random() * (4499 - 4000 + 1) + 3000);
    setCount(randomCount);
    setClicked(true);
  };

  const generateRandomCountThree = (data, option) => {
    const randomCount = Math.floor(Math.random() * (3499 - 3000 + 1) + 2000);
    setCount(randomCount);
    setClicked(true);
    setRange(option.value);
    setSelectedOption(option);
    setInputValues(option.label);
    console.log(option, "item");
    console.log(option.label, "vluee");
    // setSelectedValue(value);
  };

  console.log(selectedOption, "selectedOption");

  const onChangeData = (data, option) => {
    setInputValues(data);
    setSelectedOption(option);

    console.log(data, "data");
    console.log(option, "option");
  };

  const generateRandomCountFour = () => {
    const randomCount = Math.floor(Math.random() * (2000 - 1000 + 1) + 1000);
    setCount(randomCount);
    setClicked(true);
  };

  // const headers = {
  //   "x-access-token": userData.access_token,
  // };

  const getExperts = () => {
    setLoading(true);
    axios
      .get(`${API_PATH.EXPERT_LIST}`, {
        params: {
          country,
          company,
          tags,
          industry,
          searchRgx,
        },
      })
      .then((res) => {
        setLoading(false);
        setExperts([...res.data.data]);
        console.log([...res.data.data], "datasss");
        setAlldatas(res.data.count);
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  };

  // console.log(allDatas);

  useEffect(() => {
    getExperts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, country, company, tags, industry, searchRgx]);

  function capitalizeFirstLetter(string) {
    return string.toLowerCase();
    // return newString.charAt(0).toUpperCase() + newString.slice(1);
  }

  console.log(capitalizeFirstLetter("foo")); // Foo

  let timeout;
  let scroll1 = 0;
  useEffect(() => {
    window.onscroll = () => {
      scroll1 = window.scrollY;
      console.log("scroll1 ", scroll1);
      if (window.innerWidth > 1680) {
        if (scroll1 > 2800) {
          document.getElementById("asset-1").classList.remove("active");
          document.getElementById("asset-2").classList.remove("active");
          document.getElementById("asset-3").classList.add("active");
        } else if (scroll1 > 2300) {
          document.getElementById("asset-1").classList.remove("active");
          document.getElementById("asset-2").classList.add("active");
          document.getElementById("asset-3").classList.remove("active");
        } else if (scroll1 > 1600) {
          document.getElementById("asset-1").classList.add("active");
          document.getElementById("asset-2").classList.remove("active");
          document.getElementById("asset-3").classList.remove("active");
        }
      } else {
        if (scroll1 > 2400) {
          document.getElementById("asset-1").classList.remove("active");
          document.getElementById("asset-2").classList.remove("active");
          document.getElementById("asset-3").classList.add("active");
        } else if (scroll1 > 1900) {
          document.getElementById("asset-1").classList.remove("active");
          document.getElementById("asset-2").classList.add("active");
          document.getElementById("asset-3").classList.remove("active");
        } else if (scroll1 > 1200) {
          document.getElementById("asset-1").classList.add("active");
          document.getElementById("asset-2").classList.remove("active");
          document.getElementById("asset-3").classList.remove("active");
        }
      }

      timeout = setTimeout(() => {
        if (scroll1 >= 150) {
          document.getElementById("header").classList.add("sticky");
        } else {
          document.getElementById("header").classList.remove("sticky");
        }
      }, 10);
    };

    AOS.init();
    AOS.refresh();
  }, []);

  function truncate(str, length) {
    return str.length > length ? str.substring(0, length) + "..." : str;
  }

  const articleStructured = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Nextyn Advisory",
    alternateName: "Nextyn Advisory",
    url: "https://nextyn.com/",
    logo: "https://nextyn.com/static/media/main_logo.5691095e.svg",
    sameAs: [
      "https://www.linkedin.com/company/14536233",
      "https://twitter.com/NextynAdvisory",
      "https://medium.com/@nextyn.advisory",
    ],
  };

  return (
    <>
      <div>
        <Helmet>
          <title>Nextyn | On Demand Expert Network</title>
          <meta
            name="description"
            content={
              "Nextyn is a global on-demand expert network. We provide investment and business leaders with access to industry knowledge that helps in driving better-informed decisions."
            }
          />
          <meta
            name="keywords"
            content={
              "Expert network, expert networks, top expert network companies, expert network companies in india, expert network consulting, expert network industry, nextyn expert network"
            }
          />
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-613917167"
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-613917167');
          `}
          </script>
        </Helmet>
        <script type="application/ld+json">
          {JSON.stringify(articleStructured)}
        </script>
        <Header2 />
        <section className="topSection colord-heading2">
          <div className="container-lg">
            <div className="row d-flex justify-content-center content-section">
              <div className="col-12 text-center">
                <img
                  src={transparent_logo}
                  alt="logo"
                  style={{ maxWidth: "42%" }}
                />

                <h5 className="sub-heading mt-1 mb-5" style={{ color: "#fff" }}>
                  <span
                    data-aos-duration="1500"
                    style={{
                      display: "inline-block",
                      fontWeight: "400",
                      fontSize: "40px",
                    }}
                    data-aos-delay="2500"
                    data-aos="fade-up"
                  >
                    Search & Consult over 100,000 experts on our Cloud
                  </span>{" "}
                  {/* <span
                        data-aos-duration="1500"
                        style={{ display: "inline-block" }}
                        data-aos-delay="2750"
                        data-aos="fade-up"
                      >
                        Consult.
                      </span>{" "}
                      <span
                        data-aos-duration="1500"
                        style={{ display: "inline-block" }}
                        data-aos-delay="3000"
                        data-aos="fade-up"
                      >
                        Collaborate.
                      </span> */}
                </h5>
                <div className="d-md-flex mobi justify-content-center align-items-center">
                  <div className="mt-5">
                    <h1>Why pay over $1000 for expert calls ?</h1>
                    <h1 className="mt-4">
                      Find out how much top industry experts actually charge
                    </h1>
                    {/* <div className="d-flex justify-content-between align-items-center mt-5">
                          <div className="form-group">
                            <label className="me-1"> Industry </label>
                            {customColors.map((color) => (
                              <Tooltip
                                placement="bottom"
                                color={color}
                                title={text}
                                style={{ backgroundColor: "#ffff" }}
                              >
                                <InfoIcon
                                  className="Interest-icon"
                                  style={{ width: "20px", color: "#ffff" }}
                                />
                              </Tooltip>
                            ))}
                            <AutoComplete
                              dataSource={allIndustry}
                              onSelect={handleSelect}
                              // onSearch={handleSearch}
                            >
                              <Input
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </AutoComplete>
                          </div>
                          <div className="form-group">
                            <label className="me-1"> Expertise </label>
                            {customColors.map((color) => (
                              <Tooltip
                                placement="bottom"
                                color={color}
                                title={text2}
                              >
                                <InfoIcon
                                  className="Interest-icon"
                                  style={{ width: "20px", color: "#ffff" }}
                                />
                              </Tooltip>
                            ))}
                            <AutoComplete
                              style={{
                                width: 220,
                              }}
                              dataSource={allTags}
                              onSelect={handleSelect}
                            >
                              <Input
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </AutoComplete>
                          </div>
                          <div className="form-group">
                            <label className="me-1">
                              {" "}
                              Years of experience{" "}
                            </label>
                            {customColors.map((color) => (
                              <Tooltip
                                placement="bottom"
                                color={color}
                                title={text3}
                                style={{ backgroundColor: "#ffff" }}
                              >
                                <InfoIcon
                                  className="Interest-icon"
                                  style={{ width: "20px", color: "#ffff" }}
                                />
                              </Tooltip>
                            ))}
                            <AutoComplete
                              style={{
                                width: 220,
                              }}
                              dataSource={allCompany}
                              onSelect={handleSelect}
                            >
                              <Input
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </AutoComplete>
                          </div>
                          <div className="form-group">
                            <label className="me-1"> Geography </label>
                            {customColors.map((color) => (
                              <Tooltip
                                placement="bottom"
                                color={color}
                                title={text4}
                                style={{ backgroundColor: "#ffff" }}
                              >
                                <InfoIcon
                                  className="Interest-icon"
                                  style={{ width: "20px", color: "#ffff" }}
                                />
                              </Tooltip>
                            ))}
                            <AutoComplete
                              style={{
                                width: 220,
                              }}
                              dataSource={allCountry}
                              onSelect={handleSelect}
                            >
                              <Input
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                            </AutoComplete>
                          </div>
                        </div> */}
                    <div>
                      <TopSelectionOption
                        setCompany={setCompany}
                        setIndustry={setIndustry}
                        setExpertise={setExpertise}
                        setCountry={setCountry}
                        setCount={setCount}
                        setClicked={setClicked}
                        clicked={clicked}
                        generateRandomCount={generateRandomCount}
                        generateRandomCountTwo={generateRandomCountTwo}
                        generateRandomCountThree={generateRandomCountThree}
                        generateRandomCountFour={generateRandomCountFour}
                        selectedValue={selectedValue}
                        onChangeData={onChangeData}
                        inputValues={inputValues}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="clientSection2 range">
          <div className="container-lg">
            {/* <p>
              Price Range: ${priceRange.min} - ${priceRange.max}
            </p> */}

            <RangeSlider
              setRange={setRange}
              range={range}
              count={count}
              clicked={clicked}
            />
          </div>
        </section>

        <section className="clientSection2 range">
          <div className="container-lg">
            {/* <p>
              Price Range: ${priceRange.min} - ${priceRange.max}
            </p> */}
            <TopIndustry count={count} props={experts} clicked={clicked} />
          </div>
        </section>

        <section className="expertCloudSection mt-100">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-12 text-center">
                <h1>Expert Cloud</h1>
                <p className="subH">
                  Nextyn's <span className="colord-heading">proprietary</span>{" "}
                  platform gives clients access to{" "}
                  <span className="colord-heading">
                    qualified industry experts
                  </span>
                </p>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-12 col-xxl-12 text-center">
                <div className="d-none d-md-block">
                  <div className="d-flex align-items-stretch mt-5">
                    <div className="flex-1">
                      <div className="expertSticky text-left">
                        <div className="pos-relative">
                          <img
                            src={cloudBox}
                            className="expert_Cloud_bg"
                            alt="cloudBox"
                          />
                          <img
                            src={searchImg}
                            id="asset-1"
                            className="assetImg asset-1 active"
                            alt="search"
                          />
                          <img
                            src={consult}
                            id="asset-2"
                            className="assetImg asset-2"
                            alt="consult"
                          />
                          <img
                            src={collabarate}
                            id="asset-3"
                            className="assetImg asset-3"
                            alt="collabarate"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="contentBox mb-300 text-left">
                        <h1 className="inner-heading mb-4">Search</h1>
                        <p className="mb-1 medium-small">
                          Our advanced NLP based search lets you identify
                          experts that match your project requirements
                        </p>
                        <p className="mb-1 blue-colored medium-small">
                          Search based on:
                        </p>
                        <ul
                          className="searchUL mt-3"
                          style={{ maxWidth: "70%" }}
                        >
                          <li>Industry</li>
                          <li>Designation</li>
                          <li>Company</li>
                          <li>Expertise</li>
                          <li>Price</li>
                          <li>Location</li>
                        </ul>
                        <p className="mb-1 blue-colored medium-small">
                          at the click of a button
                        </p>
                        <p className="mb-5 medium-small">
                          Pre-vetted marketplace of experts from over 90
                          geographies and 30 industries
                        </p>
                        {/* <a style={{ display: 'block', marginTop: "50px" }}><button className="btn filled-btn height55 width250">Get Started <RightOutlined /></button></a> */}
                      </div>

                      <div className="contentBox mb-300 text-left">
                        <h1 className="inner-heading mb-4">Consult</h1>
                        <p className="mb-2 appendDot">
                          Consult shortlisted experts at your convenience
                        </p>
                        <p className="mb-2 appendDot">
                          Pay as you go, only for successful consultations
                        </p>
                        {/* <p className="mb-2 appendDot">
                          Consulting rates starting at{" "}
                          <span className="blue-colored">$450/hr</span>
                        </p> */}
                        <p className="mb-5 appendDot">
                          Credit worthy compliance procedures, to ensure a
                          seamless consulting experience
                        </p>
                        {/* <a style={{ display: 'block', marginTop: "50px" }}><button className="btn filled-btn height55 width250">Get Started <RightOutlined /></button></a> */}
                      </div>

                      <div className="contentBox mb-200 text-left">
                        <h1 className="inner-heading mb-4">Collaborate</h1>
                        <p className="mb-2 appendDot">
                          Access call recordings for completed consultations
                        </p>
                        <p className="mb-2 appendDot">
                          Automated call transcription for all completed
                          consultations
                        </p>
                        <p className="mb-2 appendDot">
                          All interactions history accessible at one location,
                          forever
                        </p>
                        <p className="mb-5 appendDot">
                          Share, comment, collaborate with team members
                          internally
                        </p>
                        <a
                          href={"https://cloud.nextyn.com/demo"}
                          style={{ display: "block", marginTop: "50px" }}
                        >
                          <button className="btn filled-btn height55 width250">
                            Get Started <RightOutlined />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-none mt-5 mobile">
                  <div>
                    <div className="imgS">
                      <img
                        src={cloudBox}
                        className="expert_Cloud_bg"
                        alt="cloudBox"
                      />
                      <img
                        src={searchImg}
                        id="asset-1"
                        className="assetImg asset-1 active"
                        alt="search"
                      />
                    </div>
                    <div className="contentBox mt-3 text-left">
                      <h1 className="inner-heading mb-4">Search</h1>
                      <p className="mb-1 medium-small">
                        Our advanced NLP based search lets you identify experts
                        that match your project requirements
                      </p>
                      <p className="mb-1 blue-colored medium-small">
                        Search based on:
                      </p>
                      <ul className="searchUL mt-3">
                        <li>Industry</li>
                        <li>Designation</li>
                        <li>Company</li>
                        <li>Expertise</li>
                        <li>Price</li>
                        <li>Location</li>
                      </ul>
                      <p className="mb-1 blue-colored medium-small">
                        at the click of a button
                      </p>
                      <p className="mb-5 medium-small">
                        Pre-vetted marketplace of experts from over 90
                        geographies and 30 industries
                      </p>
                      {/* <a style={{ display: 'block', marginTop: "50px" }}><button className="btn filled-btn height55 width250">Get Started <RightOutlined /></button></a> */}
                    </div>
                  </div>

                  <div>
                    <div className="imgS">
                      <img
                        src={cloudBox}
                        className="expert_Cloud_bg"
                        alt="cloudBox"
                      />
                      <img
                        src={consult}
                        id="asset-2"
                        className="assetImg asset-2"
                        alt="consult"
                      />
                    </div>
                    <div className="contentBox mt-3 text-left">
                      <h1 className="inner-heading mb-4">Consult</h1>
                      <p className="mb-2 appendDot">
                        Consult shortlisted experts at your convenience
                      </p>
                      <p className="mb-2 appendDot">
                        Pay as you go, only for successful consultations
                      </p>
                      {/* <p className="mb-2 appendDot">
                        Consulting rates starting at{" "}
                        <span className="blue-colored">$450/hr</span>
                      </p> */}
                      <p className="mb-5 appendDot">
                        Credit worthy compliance procedures, to ensure a
                        seamless consulting experience
                      </p>
                      {/* <a style={{ display: 'block', marginTop: "50px" }}><button className="btn filled-btn height55 width250">Get Started <RightOutlined /></button></a> */}
                    </div>
                  </div>

                  <div>
                    <div className="imgS">
                      <img
                        src={cloudBox}
                        className="expert_Cloud_bg"
                        alt="cloudBox"
                      />
                      <img
                        src={collabarate}
                        id="asset-3"
                        className="assetImg asset-3"
                        alt="collabarate"
                      />
                    </div>
                    <div className="contentBox mt-3 text-left">
                      <h1 className="inner-heading mb-4">Collaborate</h1>
                      <p className="mb-2 appendDot">
                        Access call recordings for completed consultations
                      </p>
                      <p className="mb-2 appendDot">
                        Automated call transcription for all completed
                        consultations
                      </p>
                      <p className="mb-2 appendDot">
                        All interactions history accessible at one location,
                        forever
                      </p>
                      <p className="mb-5 appendDot">
                        Share, comment, collaborate with team members internally
                      </p>
                      <a
                        href={"https://cloud.nextyn.com/demo"}
                        style={{ display: "block", marginTop: "50px" }}
                      >
                        <button className="btn filled-btn height55 width250">
                          Get Started <RightOutlined />
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="clientSection">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-12 text-center">
                <h1 className="colord-heading content-fit">
                  Our experts have worked with the best in the industry
                </h1>
              </div>
              <div className="col-12 col-xl-8 col-lg-10 text-center">
                <img
                  className="width100 mt-5 d-none d-md-block"
                  src={brand}
                  alt="brand"
                />
                <img
                  className="width100 mt-5 d-block d-md-none"
                  style={{
                    maxWidth: "500px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  src={mobBrand}
                  alt="brand"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="contactUs mt-100">
          <div className="container-lg">
            <div className="row justify-content-center">
              <div className="col-12 col-xxl-12">
                <div className="pos-relative">
                  <img src={nextynBg} className="width100" alt="bg" />
                  <div className="nextynBg">
                    <h1 className="mb-3">Contact us</h1>
                    <h1 className="mb-3">to learn more about the platform</h1>

                    <Link to={"/contact-us"}>
                      <button className="btn getInTouch">Contact Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}

export default Home;
