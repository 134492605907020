import { AutoComplete, Slider } from "antd";
import React, { useState } from "react";

const Demo = () => {
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000);

  const handleSliderChange = (newMin, newMax) => {
    setMinPrice(newMin);
    setMaxPrice(newMax);
  };

  const handleAutocompleteSelect = (selectedItem) => {
    setMinPrice(selectedItem.minPrice);
    setMaxPrice(selectedItem.maxPrice);
  };
  return (
    <div>
      <div>
        <Slider
          marks={{ [minPrice]: minPrice, [maxPrice]: maxPrice }}
          onChange={handleSliderChange}
          step={50}
          range={[minPrice, maxPrice]}
        />
        <AutoComplete onSelect={handleAutocompleteSelect} />
      </div>
    </div>
  );
};

export default Demo;
