import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../API/Config";
// import logo from "../assets/img/main_logo.svg";
import Logo from "../assets/img/Logo 155x42.svg";
import nextyn20 from "../assets/img/Nextyn_Logo 155x42.svg";

function Header() {
  let [active, setActive] = useState();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    var login_wrapper = document.getElementById("login_wrapper");
    var register_wrapper = document.getElementById("register_wrapper");
    var wrapperClass = document.getElementById("wrapperClass");

    if (login_wrapper) {
      login_wrapper.addEventListener("mouseenter", function () {
        setActive("loginActive");
      });
    }
    if (register_wrapper) {
      register_wrapper.addEventListener("mouseenter", function () {
        setActive("registerActive");
      });
    }
    if (wrapperClass) {
      wrapperClass.addEventListener("mouseleave", function () {
        setActive("");
      });
    }
  });

  return (
    <>
      <header className="header header-section" id="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container d-none d-lg-block">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-between"
              id="navbarTogglerDemo01"
            >
              <a href="https://nextyn.com" className="navbar-brand">
                <img alt="nextyn_logo" className="header_logo" src={nextyn20} />
              </a>
              {/* <div className="navbar-nav mb-2 mb-lg-0">
                <a
                  href="https://www.producthunt.com/posts/nextyn-expert-cloud?
utm_source=badge-featured&utm_medium=badge&utm_souce=badge-
nextyn&#0045;expert&#0045;cloud"
                  target="_blank"
                >
                  <img
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?
post_id=375642&theme=light"
                    alt="Nextyn&#0032;&#0045;&#0032;Expert&#0032;Cloud - 
Search&#0044;&#0032;consult&#0032;and&#0032;collaborate&#0032;wit
h&#0032;industry&#0032;experts | Product Hunt"
                    style={{ width: "250px", height: "42px" }}
                    width="250"
                    height="54"
                  />
                </a>
              </div> */}
              <div
                className="d-flex align-items-center profileInfo"
                style={{ width: "unset" }}
              >
                <div className="navbar-nav mb-2 mb-lg-0"></div>
                {/* <Link className="nav-item" to={"/"}>
                  Contact Us
                </Link> */}
                <div
                  className={active + " wrapperClass"}
                  id="wrapperClass"
                  style={{ zIndex: "99" }}
                >
                  <div className="pos-relative d-flex">
                    <button
                      className="btn not-filled-btn width120 login_wrapper"
                      style={{ zIndex: "100" }}
                      id="login_wrapper"
                    >
                      Login
                    </button>
                    <button
                      className="btn filled-btn width120 register_wrapper"
                      id="register_wrapper"
                      style={{ zIndex: "100" }}
                    >
                      Register
                    </button>
                  </div>
                  <div className="loginDetails">
                    <ul className="loginSignup login_1">
                      <li>
                        <a className="" href={config.dashboardClient}>
                          <span>Client Login</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href={config.dashboardUrl + "/expert-login"}
                        >
                          <span>Expert Login</span>
                        </a>
                      </li>
                    </ul>
                    <ul className="loginSignup register_1">
                      <li>
                        <a className="" href={config.dashboardClient + "/demo"}>
                          <span>Client Signup</span>
                        </a>
                      </li>
                      <li>
                        <a
                          className=""
                          href={config.dashboardUrl + "/expert-register"}
                        >
                          <span>Expert Signup</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-lg d-block d-lg-none">
            <div class="d-flex justify-content-between align-items-center">
              <Link to={"/"}>
                <img src={Logo} alt="Logo" />
              </Link>
              <MenuOutlined className="mobIcon" onClick={() => showDrawer()} />
            </div>
          </div>
        </nav>
      </header>

      <Drawer
        placement="right"
        className="mobileHeader"
        onClose={onClose}
        visible={visible}
      >
        <ul>
          <li>
            <a
              href="https://www.producthunt.com/posts/nextyn-expert-cloud?
utm_source=badge-featured&utm_medium=badge&utm_souce=badge-
nextyn&#0045;expert&#0045;cloud"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?
post_id=375642&theme=light"
                alt="Nextyn&#0032;&#0045;&#0032;Expert&#0032;Cloud - 
Search&#0044;&#0032;consult&#0032;and&#0032;collaborate&#0032;wit
h&#0032;industry&#0032;experts | Product Hunt"
                style={{ width: "250px", height: "42px" }}
                width="250"
                height="54"
              />
            </a>
          </li>
          <li>
            <Link to={"/contact-us"} onClose={onClose}>
              Contact Us
            </Link>
          </li>
          <hr />
          <li>
            <a href={config.dashboardClient}>Client Login</a>
          </li>
          <li>
            <a href={config.dashboardUrl + "/expert-login"}>Expert Login</a>
          </li>
          <hr />
          <li>
            <a href={config.dashboardClient + "/demo"}>Client Signup</a>
          </li>
          <li>
            <a href={config.dashboardUrl + "/expert-register"}>Expert Signup</a>
          </li>
        </ul>
      </Drawer>
    </>
  );
}

export default Header;
