const config = {
  dashboardUrl: "https://platform.nextyn.com",
  dashboardClient: "https://client.nextyn.com",
  baseUrl: "https://api.expertcloud.nextyn.com/app/",

  headers: {
    "Content-Type": "application/json",
  },

  endpoints: {
    contactUs: "contactUs",
  },
};

export default config;
