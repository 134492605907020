import env from "./env";

const API_URL = {
  LOGINURL: `${env.BASE_URL}/user/client-login`,
  GET_USER_DATA: `${env.BASE_URL}/user/getUserData`,
  SIGNUPURL: `${env.SQL_HOST}/client_registration`,
  OTPURL: `${env.BASE_URL}`,
  STEP: `${env.BASE_URL}`,


  // dashboard apis
  EXPERT_LIST: `${env.BASE_URL}/expert-list/expert-rate-list`, // /pagenumber
  EXPERT_OPTION: `${env.BASE_URL}/expert-list/get-all-option`,
  SHARE_EXPERT: `${env.BASE_URL}/expert-list/update`, // /pagenumber
  DELETE_SHARE_EXPERT: `${env.BASE_URL}/expert-list/delete`,
  GETSINGLE_EXPERT: `${env.BASE_URL}/expert-list/get-one-expert`,
  EXPERT_SCHEDULE: `${env.BASE_URL}/expert-list/get-one-expert-schedule`,
  ADD_REQUESTED_EXPERT: `${env.BASE_URL}/expert-list/add-requested-expert`,

  //demoregistration apis
  DEMO_POST: `${env.BASE_URL}/demo-user/add`,

  // change password
  CHANGE_PASSWORD: `${env.BASE_URL}/user/update`,

  // project apis
  PRJECT_LIST: `${env.SQL_HOST}/client_project_list`,
  PRJECT_DETAILS: `${env.SQL_HOST}/client_project_detail`,
  PRJECT_STATUS_CHANGE: `${env.SQL_HOST}/change_project_status`,

  // user management
  USER_MANAGEMENT: `${env.BASE_URL}/user/get-all-user`, //
  USER_MANAGEMENT_POST: `${env.BASE_URL}/user/create-user`, //
  USER_MANAGEMENT_DELETE: `${env.BASE_URL}/user/delete-user`, //
  USER_MANAGEMENT_UPDATE: `${env.BASE_URL}/user/update`, //

  //roles
  ROLE_MANAGEMENT: `${env.BASE_URL}/role/list`, //
  ROLE_MANAGEMENT_POST: `${env.BASE_URL}/role/add`, //
  ROLE_MANAGEMENT_UPDATE: `${env.BASE_URL}/role/update`, //
  ROLE_MANAGEMENT_DELETE: `${env.BASE_URL}/role/delete`, //

  // Client_Project overview details
  Client_Project_Expert_List: `${env.SQL_HOST}/client_project_expert_list`,
  CLIENT_ADMIN_CHAT_DATA: `${env.SQL_HOST}/client_admin_chat_all_data`,
  ONE_EXPERT: `${env.BASE_URL}/user/send-mail-to-expert`,
  ONE_EXPERT_STARRED: `${env.BASE_URL}/expert-list/add-starred-expert`,
  ALL_STARRED_EXPERT: `${env.BASE_URL}/expert-list/get-all-stared-expert`,
  CLIENT_PROJECT_DETAILS: `${env.SQL_HOST}/client_project_detail`,
  CLIENT_ADD_TEAM_MEMBER: `${env.SQL_HOST}/add_client_team_member`,
  REMOVE_INVITES: `${env.SQL_HOST}/remove_invites`,
  CHAT_INSERT: `${env.SQL_HOST}/chat_insert`,
  CHAT_SEEN: `${env.SQL_HOST}/admin_msg_seen`,
  CHAT_CLIENT_SEEN: `${env.SQL_HOST}/client_msg_seen`,
  CHAT_NEW_MESSAGE: `${env.SQL_HOST}/get_client_chat_msg`,
  ALL_CLIENT_PROJECT_EXPERT_LIST: `${env.SQL_HOST}/client_all_project_expert_list`,

  //news apis
  NEWS_LIST: `${env.BASE_URL}/news/getNews`, //

  CATEGORY_UPDATE: `${env.BASE_URL}/website-user/update`,

  //ExpertDetails
  Expert_Details: `${env.SQL_HOST}/expert_details`,
  EXPERT_POPUP_DETAILS: `${env.SQL_HOST}/expert_popup_details`,
  CLIENT_SCHEDULE: `${env.SQL_HOST}/client_schedule`,
  CLIENT_SCHEDULECANCEL: `${env.SQL_HOST}/client_scheduleCancel`,
  CLIENT_DECLINE: `${env.SQL_HOST}/client_declined`,

  //collaboration
  NOTE_LIST: `${env.BASE_URL}/note/getNotes`, //
  RECENTLY_VIEWD: `${env.BASE_URL}/note/getRecentlyViewd`, //
  NOTE_CREATE: `${env.BASE_URL}/note/createNote`, //
  NOTE_DETAILS: `${env.BASE_URL}/note/getSingleNote`, //
  NOTE_UPDATE: `${env.BASE_URL}/note/updateNote`, //?NoteID="PASS THE ID HERE"
  NOTE_DELETE: `${env.BASE_URL}/note/deleteNote`, //?NoteID="PASS THE ID HERE"
  NOTE_PERMISSION: `${env.BASE_URL}/note/updateNotePermission`, // /noteid/currentuserId mothod --put
  REMOVE_NOTE: `${env.BASE_URL}/note/removeNote`, //?noteId='' ,userId=''  mothod --delete

  CONTENT_LIST: `${env.BASE_URL}/content/getContents`, // /userId
  CONTENT_CREATE: `${env.BASE_URL}/content/createContent`, //
  CONTENT_DETAILS: `${env.BASE_URL}/content/getSingleContent`, //ContentID
  CONTENT_UPDATE: `${env.BASE_URL}/content/updateContent`, // ?ContentID="PASS THE ID HERE"
  CONTENT_DELETE: `${env.BASE_URL}/content/deleteContent`, //?ContentID="PASS THE ID HERE"
  CONTENT_PERMISSION: `${env.BASE_URL}/content/updateContentPermission`, // /ContentID/currentuserId mothod --put
  REMOVE_CONTENT: `${env.BASE_URL}/content/removeContent`, //?contentId='' ,userId=''  mothod --delete

  COVER_LIST: `${env.BASE_URL}/cover/getCovers`, //
  COVER_CREATE: `${env.BASE_URL}/cover/createCover`, //
  COVER_DETAILS: `${env.BASE_URL}/cover/getSingleCover`, //
  COVER_UPDATE: `${env.BASE_URL}/cover/updateCover`, //?coverID="PASS THE ID HERE"
  COVER_DELETE: `${env.BASE_URL}/cover/deleteCover`, //?coverID="PASS THE ID HERE"
  COVER_PERMISSION: `${env.BASE_URL}/cover/updateCoverPermission`, // /coverID/currentuserId mothod --put
  REMOVE_COVER: `${env.BASE_URL}/cover/removeCover`, //?coverId='' ,userId=''  mothod --delete

  MEETING_LIST: `${env.BASE_URL}/zoom-meeting/getMeetings`, //params companydomain
  MEETING_ID_GET: `${env.BASE_URL}/zoom-recording/getOne`, //params companydomain
  MEETING_ID_UPDATE: `${env.BASE_URL}/zoom-recording/update`, //params companydomain

  SLEEVE_LIST: `${env.BASE_URL}/sleeve/getSleeve`, // /userId
  SLEEVE_CREATE: `${env.BASE_URL}/sleeve/createSleeve`, //
  SLEEVE_DETAILS: `${env.BASE_URL}/sleeve/getSingleSleeve`, // /sleeveId
  SLEEVE_UPDATE: `${env.BASE_URL}/sleeve/updateSleeve`, // /sleeveId
  SLEEVE_DELETE: `${env.BASE_URL}/sleeve/deleteSleeve`, // /sleeveId
  SLEEVE_PERMISSION: `${env.BASE_URL}/sleeve/updateSleevePermission`, // /sleeveId/currentuserId mothod --put
  REMOVE_SLEEVE: `${env.BASE_URL}/sleeve/removeSleeve`, //?sleeveId='' ,userId=''  mothod --delete

  //Conversation
  APP_ID: "19A8592E-92E0-4320-8F57-E9C919F47B17",
  Api_Token: "d19dd8f5462dbdc20a1ad1f47dbbf99e01313da3",

  //Help & Support
  SEND_EMAIL: `${env.BASE_URL}/user/send-mail`, //

  //get expire time
  EXPIRE_TIME:`${env.BASE_URL}/user/get-expire-time`,

  //project list
  LIVE_PROJECT:`${env.SQL_HOST}/client_live_project_list`,
  HOLD_PROJECT:`${env.SQL_HOST}/client_archive_onhold_project_list`,
  COMPLETE_PROJECT:`${env.SQL_HOST}/client_completed_project_list`,

  //find-experts
  CREATE_PROJECT: `${env.SQL_HOST}/client_project_add`,

  //upload file to s3
  UPLOAD_MEDIA: `${env.BASE_URL}/s3upload/uploadFile`, //

  //forget password
  FORGOT_PASSWORD: `${env.BASE_URL}/user/forgot-password`,
  RESET_PASSWORD: `${env.BASE_URL}/user/reset-password`,
// call list
   CALL_LIST:`${env.SQL_HOST}/all_call_history_with_filter`,
  //elastic search
  ELASTIC_SEARCH_BY_QUERY: `${env.BASE_URL}/elastic/search`,
  ELASTIC_SEARCH: `${env.BASE_URL}/elastic/posts`,

  GET_NOTIFICATION_BY_SOCKET: `${env.BASE_URL}/notification/user-notification`, // / currentuserId METHOD -- GET
  UPDATE_NOTIFICATION_READ_STATUS: `${env.BASE_URL}/notification/update-notification`, // METHOD -- GET
};

export default API_URL;
