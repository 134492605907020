import React from 'react'
import { Link } from 'react-router-dom';
function Insights(props) {
    function truncate(str, length) {
        return str.length > length ? str.substring(0, length) + "..." : str;
    }
    return (
        <div>
            <div>
                <div style={{ borderRadius: '7px', overflow: 'hidden', maxHeight: '500px' }}>
                    <Link to={`/articles/${props.defaultKey}`}><img src={props.img} className="width100" style={{ objectFit: 'cover' }} /></Link>
                </div>
                <div className="newsInsightsContent">
                    <Link to={`/articles/${props.defaultKey}`}><h5>{truncate(props.title, 45)}</h5></Link>
                    <p>{truncate(props.subHead, 85)}</p>
                    <Link to={`/articles/${props.defaultKey}`} className="findOutMore"><span>FIND OUT MORE</span> </Link>
                    {/* <div className="d-flex">
                        {props.tags.map((tag, index) => (
                            <>
                                {index < 2 ? (<><div>
                                    {tag}
                                </div></>) : ('')}
                            </>
                        ))}
                    </div> */}
                    {/* <div className="timeStamp">{props.timeStamp}</div> */}
                </div>
            </div>
        </div>
    )
}

export default Insights