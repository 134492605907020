import React from "react";
import { Helmet } from "react-helmet";
import favIcon from "../assets/img/nextyn_small_logo.png";
function HelmetComponent({ title, desc, keywords, script }) {
  return (
    <div className="application">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content="index, follow" />
        <script>{script}</script>
      </Helmet>
    </div>
  );
}
export default HelmetComponent;
