import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Demo from "./demo/Demo";
import Compliance from "./pages/Compliance";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";
import Home from "./pages/Home";
import NewsAndInsights from "./pages/NewsAndInsights";
import PricingTool from "./pages/PricingTool";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SingleNewsAndInsight from "./pages/SingleNewsAndInsight";
import TermsAndUse from "./pages/TermsAndUse";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      maxSnack={3}
      autoHideDuration={5000}
    >
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<ContactUs />}></Route>
        </Routes>
        <Routes>
          <Route path="/faqs" element={<Faq />}></Route>
        </Routes>
        <Routes>
          <Route
            path="/news-and-insights"
            element={<NewsAndInsights />}
          ></Route>
        </Routes>
        <Routes>
          <Route
            path="/articles/:blogId"
            element={<SingleNewsAndInsight />}
          ></Route>
        </Routes>
        {/* <Routes>
          <Route path="/contact-us" element={<ContactUs />}></Route>
        </Routes> */}
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        </Routes>
        <Routes>
          <Route path="/terms-and-use" element={<TermsAndUse />}></Route>
        </Routes>
        <Routes>
          <Route path="/compliance" element={<Compliance />}></Route>
        </Routes>

        {/* pricing tool */}
        <Routes>
          <Route path="/pricing-tool" element={<PricingTool />}></Route>
        </Routes>

        <Routes>
          <Route path="/testing-demo" element={<Demo />}></Route>
        </Routes>
      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
