import { DownOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import { AutoComplete, Input, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import "../App.css";

const text = (
  <p style={{ fontSize: "12px" }}>
    <span>
      Industry refers to the sector or branch of a economy that produces a
      particular good or service.
    </span>
  </p>
);

const text2 = (
  <p style={{ fontSize: "12px" }}>
    <span>
      Functional expertise refers to an individual's specialized knowledge or
      skills in a specific functional area or domain.
    </span>
    <br />
    <span>
      Examples of functional expertise areas can be: finance, strategy,
      marketing, operations, technology, Human resources, and so on. depending
      on the industry.
    </span>
  </p>
);

const text3 = (
  <p style={{ fontSize: "12px" }}>
    <span>
      Years of experience refers to the length of time an individual has been
      working or has spent in a particular field, industry, job, or profession.
    </span>
  </p>
);

const text4 = (
  <p style={{ fontSize: "12px" }}>
    <span>
      Geography refers to the physical location or region in which an
      individual, business, or industry operates.
    </span>
  </p>
);

const customColors = ["#5746ec"];

const Industries = [
  "Automobiles",
  "Alternative Energy Sources",
  "Aerospace",
  "Defense",
  "Ecommerce",
  "Agriculture Services and Processing",
  "Investment Services",
  "Retail",
  "FMCG",
  "Financial Services",
  "Insurance",
  "Telecommunications",
  "Healthcare",
  "Oil & Gas",
  "Banking",
];

const Country = [
  "APAC",
  "MENA",
  "EUROPE",
  "NORTH AMERICA",
  "SOUTH AMERICA",
  "AFRICA",
];

const options = [
  {
    label: "0 to 2 years",
    value: [150, 350],
  },
  {
    label: "3 to 5 years",
    value: [250, 450],
  },
  {
    label: "6 to 10 years",
    value: [300, 600],
  },
  {
    label: "more than 10 years",
    value: [500, 1500],
  },
];

const Expertise = [
  "Data Consulting",
  "Finance",
  "Network Design",
  "IT",
  "Marketing & Sales",
  "Payment Partnerships",
  "Project Manager",
  "Manufacturing Engineering",
  "Investment Advisory",
];

const TopSelectionOption = ({
  setCompany,
  setIndustry,
  setExpertise,
  setCountry,
  setCount,
  clicked,
  setClicked,
  generateRandomCount,
  generateRandomCountTwo,
  generateRandomCountThree,
  generateRandomCountFour,
  onChangeData,
  inputValues,
  selectedValue,
}) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [allTags, setAllTags] = useState("");
  const [allIndustry, setAllIndustry] = useState("");
  const [allCompany, setAllCompany] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedComany, setSelectedCompany] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  const [allCountry, setAllCountry] = useState("");
  const [company, setFilteredCompany] = useState("");
  const [industry, setFilteredIndustry] = useState("");
  const [tags, setFilteredExpertise] = useState("");
  const [country, setFilteredCountry] = useState("");
  const catMenu = useRef(null);

  const [inputValue, setInputValue] = useState("");
  const [inputExpertise, setInputExpertise] = useState("");
  const [inputCompany, setInputCompany] = useState("");
  const [inputCountry, setInputCountry] = useState("");

  // const Expertise = (
  //   <Menu
  //     onClick={generateRandomCountTwo}
  //     items={[
  //       {
  //         label: "Data Consulting",
  //         key: "Data Consulting",
  //       },
  //       {
  //         label: "Finance",
  //         key: "Finance",
  //       },
  //       {
  //         label: "Network Design",
  //         key: "Network Design",
  //       },
  //       {
  //         label: "IT",
  //         key: "IT",
  //       },
  //       {
  //         label: "Marketing & Sales",
  //         key: "Marketing & Sales",
  //       },
  //       {
  //         label: "Payment Partnerships",
  //         key: "Payment Partnerships",
  //       },
  //       {
  //         label: "Project Manager",
  //         key: "Project Manager",
  //       },
  //       {
  //         label: "Manufacturing Engineering",
  //         key: "Manufacturing Engineering",
  //       },
  //     ]}
  //   />
  // );

  // const Countries = (
  //   <Menu
  //     onClick={generateRandomCountFour}
  //     items={[
  //       {
  //         label: "APAC",

  //         key: "APAC",
  //       },
  //       {
  //         label: "MENA",

  //         key: "MENA",
  //       },
  //       {
  //         label: "EUROPE",
  //         key: "EUROPE",
  //       },
  //       {
  //         label: "NORTH AMERICA",
  //         key: "NORTH AMERICA",
  //       },
  //       {
  //         label: "SOUTH AMERICA",
  //         key: "SOUTH AMERICA",
  //       },
  //       {
  //         label: "AFRICA",
  //         key: "AFRICA",
  //       },
  //     ]}
  //   />
  // );

  // const Industry = (
  //   <Menu
  //     onClick={generateRandomCount}
  //     items={[
  //       {
  //         label: "Automobiles",
  //         key: "Automobiles",
  //       },
  //       {
  //         label: "Alternative Energy Sources",
  //         key: "Alternative Energy Sources",
  //       },
  //       {
  //         label: "Aerospace",
  //         key: "Aerospace",
  //       },
  //       {
  //         label: "Defense",
  //         key: "Defense",
  //       },
  //       {
  //         label: "Ecommerce",
  //         key: "Ecommerce",
  //       },
  //       {
  //         label: "Agriculture Services and Processing",
  //         key: "Agriculture Services and Processing",
  //       },
  //       {
  //         label: "Investment Services",
  //         key: "Investment Services",
  //       },
  //       {
  //         label: "Retail",
  //         key: "Retail",
  //       },
  //       {
  //         label: "FMCG",

  //         key: "FMCG",
  //       },
  //       {
  //         label: "Financial Services",
  //         key: "Financial Services",
  //       },
  //       {
  //         label: "Insurance",
  //         key: "Insurance",
  //       },
  //       {
  //         label: "Telecommunications",

  //         key: "Telecommunications",
  //       },
  //       {
  //         label: "Healthcare",
  //         key: "Healthcare",
  //       },
  //       {
  //         label: "Oil & Gas",
  //         key: "Oil & Gas",
  //       },
  //       {
  //         label: "Banking",
  //         key: "Banking",
  //       },
  //     ]}
  //   />
  // );

  // const Experience = (
  //   <Menu
  //     onClick={generateRandomCountThree}
  //     items={[
  //       {
  //         label: "0 to 2 years",
  //         key: [150, 350],
  //       },
  //       {
  //         label: "3 to 5 years",
  //         key: [250, 450],
  //       },
  //       {
  //         label: "6 to 10 years",
  //         key: [300, 600],
  //       },
  //       {
  //         label: "more than 10 years",
  //         key: [500, 1000],
  //       },
  //     ]}
  //   />
  // );

  const handleInputIndustry = (value) => {
    setInputValue(value);
  };

  const handleSelectIndustry = (value) => {
    // setFilteredIndustry(value);

    let industryOption = [value];
    // setSelectedIndustry(industryOption);
    setIndustry(industryOption.join(","));
  };

  const handleInputExpertise = (value) => {
    setInputExpertise(value);
  };

  const handleSelectExpertise = (value) => {
    // setFilteredExpertise(value);

    let expertiseOption = [value];
    // setSelectedExpertise(expertiseOption);
    setExpertise(expertiseOption.join(","));
  };

  const handleBlurTwo = () => {
    setAllTags([]);
  };

  const handleInputCompany = (value) => {
    setInputCompany(value);
  };

  const handleSelectCompany = (value) => {
    // setFilteredCompany(value);

    let companyOption = [value];
    // setSelectedCompany(companyOption);
    setCompany(companyOption.join(","));
  };

  const handleInputCountry = (value) => {
    setInputCountry(value);
  };

  const handleSelectCountry = (value) => {
    // setFilteredCountry(value);

    let companyOption = [value];
    // setSelectedCompany(companyOption);
    setCompany(companyOption.join(","));
    // setSearchCompany("");
  };

  // const filteredOptions = OPTIONS?.filter((o) => !selectedItem.includes(o));

  return (
    <div>
      <div className="d-md-flex justify-content-md-between align-items-center mt-4 selection-optn">
        <div className="form-group">
          <div className="text-left label">
            <label className="me-1"> Industry </label>

            {customColors.map((color) => (
              <Tooltip
                placement="bottom"
                color={color}
                title={text}
                style={{ backgroundColor: "#ffff" }}
              >
                <InfoIcon className="Interest-icon" style={{ width: "20px", color: "#fff" }} />
              </Tooltip>
            ))}
          </div>
          <AutoComplete
            dataSource={Industries}
            onSelect={generateRandomCount}
            // onSearch={handleInputIndustry}
            // filterOption={(inputValue, option) =>
            //   option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
            //   -1
            // }
            style={{
              width: 240,
            }}
          >
            <Input
              value={inputValue}
              onChange={handleInputIndustry}
              size="large"
              style={{ borderRadius: "8px" }}
              suffix={<DownOutlined dataSource={Industries}  />}
              // onBlur={handleBlur}
            />
          </AutoComplete>
        </div>
        <div className="form-group">
          <div className="text-left label">
            <label className="me-1"> Expertise </label>
            {customColors.map((color) => (
              <Tooltip placement="bottom" color={color} title={text2}>
                <InfoIcon className="Interest-icon" style={{ width: "20px", color: "#fff" }} />
              </Tooltip>
            ))}
          </div>
          <AutoComplete
            style={{
              width: 240,
            }}
            dataSource={Expertise}
            onSelect={generateRandomCountTwo}
            // filterOption={(inputExpertise, option) =>
            //   option.value
            //     .toUpperCase()
            //     .indexOf(inputExpertise.toUpperCase()) !== -1
            // }
          >
            <Input
              value={inputExpertise}
              onChange={handleInputExpertise}
              size="large"
              style={{ borderRadius: "8px" }}
              suffix={<DownOutlined />}
              // onBlur={handleBlurTwo}
            />
          </AutoComplete>
        </div>
        <div className="form-group">
          <div className="text-left label">
            <label className="me-1"> Years of experience </label>
            {customColors.map((color) => (
              <Tooltip
                placement="bottom"
                color={color}
                title={text3}
                style={{ backgroundColor: "#ffff" }}
              >
                <InfoIcon className="Interest-icon" style={{ width: "20px", color: "#fff" }} />
              </Tooltip>
            ))}
          </div>
          <AutoComplete
            value={inputValues}
            style={{
              width: 240,
            }}
            options={options}
            onSelect={generateRandomCountThree}
            // filterOption={(inputValues, option) =>
            //   option.label.toUpperCase().indexOf(inputValues.toUpperCase()) !==
            //   -1
            // }
            onChange={onChangeData}
          >
            <Input
              value={inputValues}
              // onChange={handleInputCompany}
              size="large"
              style={{ borderRadius: "8px" }}
              suffix={<DownOutlined />}
              // onBlur={handleBlurThree}
            />
          </AutoComplete>
        </div>
        <div className="form-group">
          <div className="text-left label">
            <label className="me-1"> Geography </label>
            {customColors.map((color) => (
              <Tooltip
                placement="bottom"
                color={color}
                title={text4}
                style={{ backgroundColor: "#ffff" }}
              >
                <InfoIcon className="Interest-icon" style={{ width: "20px", color: "#fff" }} />
              </Tooltip>
            ))}
          </div>
          <AutoComplete
            style={{
              width: 240,
            }}
            dataSource={Country}
            onSelect={generateRandomCountFour}
            // filterOption={(inputCountry, option) =>
            //   option.value.toUpperCase().indexOf(inputCountry.toUpperCase()) !==
            //   -1
            // }
          >
            <Input
              value={inputCountry}
              onChange={handleInputCountry}
              size="large"
              style={{ borderRadius: "8px" }}
              suffix={<DownOutlined />}
              // onBlur={handleBlurFour}
            />
          </AutoComplete>
        </div>
      </div>
      {/*  */}
    </div>
  );
};

export default TopSelectionOption;
