import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import nextynBg from '../assets/img/nextyn_bg.png';
import { Link } from 'react-router-dom'
import HelmetComponent from '../utils/HelmetComponent';
function PrivacyPolicy() {
    return (
        <div>
            <HelmetComponent title={'Nextyn | Privacy Policy'} desc={'This privacy policy explains how Nextyn (registered Company “Nextyn Advisory Private Limited”), (“Nextyn” “we” and “us”) obtains, utilizes, shares and safeguards information collected from Experts, Clients and visitors to its websites, including without limitation https://nextyn.com and (each, a “Website,” collectively, the “Websites”).'} />
        
            <Header />
            <section className='mt-100'>
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className='text-center'>
                                <h1 className="colord-heading text-large ">Privacy Policy</h1>
                            </div>
                            <div className='mt-100 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Privacy & Cookie Policy</h4>
                                <p>This privacy policy explains how Nextyn (registered Company “Nextyn Advisory Private Limited”), (“Nextyn” “we” and “us”) obtains, utilizes, shares and safeguards information collected from Experts, Clients and visitors to its websites, including without limitation https://nextyn.com and (each, a “Website,” collectively, the “Websites”).</p>

                                <p>If you reside in the European Union, please see Specific Disclosures for the European Economic Area, as mentioned below.</p>

                                <p>By visiting our Websites or by registering as an Expert, you consent to the collection, use and transfer of your personal data pursuant to the terms of this privacy policy.</p>
                            </div>
                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Personal Data We Collect From You</h4>
                                <p>Nextyn collects personal data that you choose to provide to us on the Websites (including information referenced in the “The Use of Cookies” section of this Privacy Policy), through email, over the phone and otherwise, including your name, contact information, location, email address, phone number, and if you are a potential Expert, we additionally collect employment and background information, social security numbers, banking information, payment preferences, information obtained from third-party background check providers and feedback concerning your experience as an Expert. We may also obtain information from third parties about you. In addition, Nextyn Websites may automatically record information when you visit. This information can include your Internet Protocol address and data concerning webpage requests.</p>

                                <p>Nextyn may place small files called “cookies” on your computer when you visit the Websites. Cookies facilitate your utilization of the Websites and enable us to track activity and customize your website experience. If you do not wish to receive a cookie from Nextyn, you may set your Internet browser settings to decline or disable the cookie.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Using Your Personal Data</h4>
                                <p>We utilize personal data collected to market our services to you and develop, support and improve our business and, in the case of Experts, to match you with consulting opportunities, notify you of Nextyn developments, process your invoices and administer honoraria.</p>

                                <p>We DO NOT sell or share your personal data with third parties for their marketing or solicitation purposes. We do not use IP addresses or web page request information to try and identify you (nor do we disclose that information to third parties for them to use for their own purposes—although we do use third parties for our internal analytics purposes and retargeting for marketing). Nextyn and trusted third parties utilize personal data only for the purposes for which it was obtained.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Disclosure of Personal Data</h4>
                                <p>Personal data provided to Nextyn will be processed and stored by us inside India and will be accessed by our staff and third parties working for us for the purposes set forth in this policy or for other purposes subject to your consent. Your personal data also may be held, processed and accessed inside the European Economic Area and/or the United States</p>

                                <p>Nextyn may share your personal data with our Clients, typically in connection with micro-consulting opportunities (as this is one of the core and necessary functions provided by our platform). Nextyn may disclose the fact that you are an Expert and personal data collected about you in response to a valid order or request by a court or other governmental body, to enforce an agreement between you and Nextyn or one of Nextyn Clients, to protect Nextyn rights, or as otherwise required by law, legal process or for the prevention or detection of a crime.</p>

                                <p>Third parties, including your current employer, may request that limitations be placed on your participation in the Network of Experts arising from duties you may owe to them. Nextyn will comply with limitations requested by an employer, through an authorized representative, for its current employees. Nextyn does this to comply with International laws and best corporate practices. Nextyn also reserves the right to abide by any limitations requested by a third party for the same reason, on a case-by-case basis. Nextyn may discuss with third parties your participation or proposed participation in any project through the platform for the purpose of verifying any factual information you provide to us or any consents or approvals that may be needed.</p>
                            </div>


                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Updating Your Information on the Platform</h4>
                                <p>As one of the Confidentiality Terms & Conditions of Engagement as an Expert, you are required to update personal, background and employment information you provide to Nextyn regularly. You are entitled to review the personal information Nextyn holds about you and may ask that we revise your personal information to ensure that it is current and correct. We may also revise your information using data obtained from third parties or public sources.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Information Security</h4>
                                <p>We use physical and technological security measures to protect your information both online and offline from unauthorized access and against illegal manipulation and processing, accidental loss and damage. We will preserve your information for a reasonable period of time or as required by law.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Opting Out</h4>
                                <p>You may elect to opt-out as an Expert from the platform at any time by providing Nextyn with written or electronic notice at info@nextyn.com. Upon receipt of such notice, we will cease contacting you and cease presenting information concerning you to Clients for potential consulting opportunities</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Updates to the Policy</h4>
                                <p>Nextyn may update this policy from time to time. To the extent permitted by law, any changes to our privacy policy will be posted to the Websites and will become effective upon posting. Your continued use of the Websites and failure to opt out of the platform will indicate your agreement to such changes.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>Contact Nextyn</h4>
                                <p>We welcome all requests, suggestions and questions concerning our use of your personal data. All such communication should be directed to info@nextyn.com.</p>
                            </div>

                            <div className='mt-5 pb-4' style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                                <h4>DISCLOSURES FOR THE EUROPEAN ECONOMIC AREA</h4>
                                <p>This section applies to data processing activities of data subjects residing in the European Economic Area as well as, regardless of residence, the processing of any Personal Data in the European Economic Area. In the event there is a conflict between this section and other parts of the Privacy Policy, this section shall control with respect to residents of the European Economic Area</p>

                                <p>“Personal Data,” for purposes of this section, means any information relating to an identified or
                                    identifiable natural person (‘data subject’). An identifiable natural person is one who can be identified,
                                    directly or indirectly, in particular by reference to an identifier such as a name, an identification
                                    number, location data, an online identifier or to one or more factors specific to the physical,
                                    physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>

                                <p>“Services” means any of our products, services, or deliverables.</p>
                                <p>“Site” means any website operated and controlled by us</p>
                                <p>Pursuant to the European Union General Data Protection Regulation (GDPR), we function in the
                                    capacity of a “processor” both for the Services we offer to our Clients as well as for the services
                                    Experts and other persons offer to our Clients through our platform. The scope of applicable data
                                    protections and processing for those Services are set forth in contractual agreements covering the
                                    relevant data processing.</p>
                                <p>Controller versus Processor. We may process certain Personal Data in order to maintain and
                                    provide the Services to our Clients, such as names, addresses, email addresses, and other contact
                                    information for designated Client representatives or personnel (“Client Relationship Data”). In
                                    addition, we may send marketing materials or communications to potential or existing Clients
                                    (“Marketing Activities”). For purposes of Client Relationship Data and Marketing Activities, we
                                    function as a “data processor” under GDPR. Our disclosures with respect to our processing of data
                                    as a data processor are set forth in this Privacy Policy.</p>
                                <h5>1. HOW WE COLLECT AND USE PERSONAL DATA</h5>
                                <p>We describe how we collect and use Personal Data in the “The Personal Data We Collect from You”
                                    section of this Privacy Policy.</p>
                                <h5>2. HOW WE DISCLOSE PERSONAL DATA</h5>
                                <p>Our disclosure is limited to circumstances where we are permitted to do so under applicable
                                    European and national data protection laws and regulations. We share data with certain types of
                                    companies, for purposes of providing Services to Clients and marketing our Services to potential and
                                    existing Clients, such as cloud computing companies, cloud communications platform as a service
                                    (PaaS), web analytics services, employment-related social networking services, and mailing and
                                    emailing services.</p>
                                <h5>3. LEGAL BASIS FOR PROCESSING</h5>
                                <p>We collect and process your Personal Data for a variety of different purposes which are set out in
                                    further detail in this section</p>
                                <p>In some cases, we will ask for your consent so that we may process your Personal Data. However, in
                                    certain circumstances, applicable data protection laws allow us to process your Personal Data without
                                    needing to obtain your consent.</p>
                                <h6>3.1. PROCESSING PERSONAL DATA WHERE CONSENT NOT REQUIRED</h6>
                                <p>In certain cases, separate consent is not required, including:</p>
                                <p>• For the performance of a contract.
                                    To perform our contractual obligations to you, including our fulfilling orders or requests you
                                    have made, contacting you in relation to any issues with your order or use of our Services, or
                                    where we need to provide your Personal Data to our service providers related to the
                                    provision of the Services.</p>
                                <p>• To comply with legal obligations.
                                    To comply with laws, regulators, court orders, or other legal obligations, or pursuant to legal
                                    process.</p>
                                <p>• Legitimate Interests.
                                    To operate our business and provide our Services (other than in performing our contractual
                                    obligations to you) for our “legitimate interests” and for the purposes of applicable law, except where such interests are overridden by the interests or fundamental rights and
                                    freedoms of the data subject which require protection of personal data. Legitimate interests
                                    may include:</p>
                                <ul>
                                    <li><p>To communicate with you regarding the Services, including to provide you
                                        important notices regarding changes to our operations and to address and respond
                                        to your requests, inquiries, and complaints.</p></li>
                                    <li><p>For our direct marketing purposes.</p></li>
                                    <li><p>To send you surveys in connection with our Services.</p></li>
                                    <li><p>To assist in the investigation of suspected illegal or wrongful activity, and to protect
                                        and defend our rights and property, or the rights or safety of third parties.</p></li>
                                    <li><p>To develop, provide, and improve our Services.</p></li>
                                    <li><p>To enforce our Terms of Use, License Agreements, or this Privacy Notice, or
                                        agreements with third parties</p></li>
                                </ul>

                                <h6>3.2. CONSENT FOR PROCESSING</h6>
                                <p>In cases where we are not already authorized to process the Personal Data under
                                    applicable law, we may ask for your consent to process your Personal Data, including:</p>
                                <ol>
                                    <li><p><strong>Marketing</strong></p>
                                        <p>We may ask for your consent for Nextyn or our partners or vendors to contact you by
                                            telephone, SMS, post and/or email about other offers, products, promotions,
                                            developments or Services which we think may be of interest to you and for other
                                            marketing purposes.</p>
                                    </li>
                                    <li><p><strong>Use of Cookies</strong></p>
                                        <p>Our Site uses cookies and similar technologies to improve user experience and for
                                            analytics. We also use cookies and similar technologies for purposes of including social
                                            network sharing capabilities on our main site, analytics, marketing, and session control</p>
                                        <p>A “cookie” is a small text file that a web server stores in browser software. A browser
                                            sends cookies to a server when the browser makes a connection to the server (for example,
                                            when requesting a web page from the same domain that created the cookie). The purpose
                                            of cookies is to remember the browser over time and distinguish one browser instance (or
                                            user) from all others. Some cookies and other technologies may serve to track Personal
                                            Data previously entered by a web user on our site. Most browsers allow visitors to the website to control cookies, including whether or not to accept them, and how to remove
                                            them. Cookies can remember login information, preferences, and similar information.</p>
                                        <p>Cookies, as well as other tracking technologies such as HTML5 local storage, and Local
                                            Shared Objects (such as “Flash” cookies), and similar mechanisms, may record
                                            information such as a unique identifier, information visitors to the website enter in a form,
                                            IP address, and other categories of data.</p>
                                        <p>We also use web beacons or “pixels,” and in certain circumstances may collect IP address,
                                            screen resolution and browser software and operating system types; clickstream patterns;
                                            dates and times that our site is accessed, and other categories of data.</p>
                                        <p>If visitors to the Site want to block the use and saving of cookies from the Site on to their
                                            computers hard drive, he or she should take the necessary steps within your web browser’s
                                            settings to block all cookies from the Site and its external serving vendors, or use the
                                            cookie control system, if available upon first visit. Please note that if visitors to the website
                                            choose to erase or block your cookies, certain parts of our Site may not function correctly.
                                            For information on how to disable cookies, refer to your browser’s documentation.</p>
                                    </li>
                                </ol>

                                <h5>4. INTEREST-BASED ADVERTISING</h5>
                                <p>Interest-based advertising is advertising that is targeted to you based on your web browsing and app
                                    usage over time and across websites or apps. You have the option to restrict the use of information
                                    for interest-based advertising and to opt-out of receiving interest-based ads. Depending on from
                                    where you access our Site, you may also be asked to consent to our use of cookies, including
                                    advertising cookies.</p>
                                <p>The opt-outs for interest-based advertising require that strictly necessary cookies are not blocked by
                                    the settings in your web browser. We support the Self-Regulatory Principles for Online Behavioral
                                    Advertising of the Digital Advertising Alliance in the U.S., the Canadian Digital Advertising
                                    Alliance in Canada, and the European Digital Advertising Alliance in the EU. If you live in the
                                    United States, Canada, or the European Union, you can visit Ad Choices (US), Your Ad
                                    Choices (Canada), or Your Online Choices (EU) to find a convenient place to opt-out of interestbased advertising with participating entities for each region. These websites also provide detailed
                                    information about how interest-based advertising works. Opting out does not mean that you will no
                                    longer receive advertising from us, or when you use the Internet. It just means that the data collected from our website will not be used for interest-based advertising and that the advertising you see
                                    displayed on websites will not be customized to your interests.</p>
                                <h5>5. WITHDRAWING YOUR CONSENT</h5>
                                <p>You may at any time withdraw the consent you provide for the processing of your Personal Data for
                                    the purposes set forth in this Privacy Notice by contacting us at info@nextyn.com, provided that we
                                    are not required by applicable law or professional standards to retain such information.</p>
                                <p>If you want to stop receiving future marketing messages and materials, you can do so by clicking the
                                    “unsubscribe” link included in our email marketing messages or by contacting us at
                                    info@nextyn.com</p>

                                <h5>6. DATA SUBJECT RIGHTS</h5>
                                <p>You have the right in certain circumstances to request confirmation from us as to whether or not we
                                    are processing your Personal Data in our capacity as a Controller. Where we are processing your
                                    Personal Data as a Controller, you also have the right to request access to, modification of, or
                                    deletion of such Personal Data.</p>
                                <p>You also have the right in certain circumstances to receive the Personal Data concerning you that you
                                    provided to us, to restrict processing of your Personal Data, or to transmit such data to another
                                    controller</p>

                                <h5>7. DE-IDENTIFIED OR ANONYMIZED DATA</h5>
                                <p>We may create de-identified or anonymous data from Personal Data by removing data components
                                    (such as your name, email address, or linkable tracking ID) that makes the data personally identifiable
                                    to you or through obfuscation or through other means. Our use of anonymized data is not subject to
                                    this Privacy Notice</p>
                                <h5>8. DATA RETENTION</h5>
                                <p>We will retain your Personal Data for as long as you remain a Client or an employee of a Client, an
                                    Expert in our Network or maintain an account or agreement or as otherwise necessary. We will also
                                    retain your Personal Data as necessary to comply with our legal obligations, resolve disputes, enforce
                                    our agreements, comply with applicable industry standards, and in accordance with disaster recovery
                                    procedures.</p>

                                <h5>9. RIGHT TO LODGE A COMPLAINT WITH A SUPERVISORY AUTHORITY</h5>
                                <p>You may have a right to lodge a complaint with a supervisory authority</p>
                                <h5>10. CONTACT US</h5>
                                <p>For questions regarding this Privacy Notice, please contact us at: info@nextyn.com.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contactUs mt-100">
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-12">
                            <div className="pos-relative">
                                <img src={nextynBg} className="width100" alt="bg" />
                                <div className="nextynBg">
                                    <h1 className="mb-3">Big enough to deliver,</h1>
                                    <h1 className="mb-5">small enough to care</h1>
                                    <Link to={'/contact-us'}><button className="btn getInTouch">Get in touch</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy